<template>
  <div class="tour-selection-container mt-3">
    <div class="row mt-3 align-items-center">
      <div class="col text-start nowrap">
        <label class="fw-bold">
          {{ minMaxChildrenParticipants.max > 0 ? $t('adults') : $t('participants') }}
        </label>
      </div>
      <div class="col text-end">
        <select
          class="form-select w-auto"
          v-model="productOptions.selectedPax"
        >
          <option
            v-for="participant in minMaxParticipants.max"
            :key="participant"
            :value="participant"
          >
            {{ participant }}
          </option>
        </select>
      </div>
      <div class="col text-end"></div>
      <div class="col text-end">
        {{ productOptions.selectedPax }} x {{ formatCurrency(getPrice(prices.prices, productOptions.selectedPax), props.currency) }}
      </div>
      <div class="col text-end">
        {{ formatCurrency(getTotalLineItemPrice(prices.prices, productOptions.selectedPax), props.currency) }}
      </div>
    </div>

    <div
      class="row mt-3 align-items-center"
      v-if="minMaxChildrenParticipants.max > 0"
    >
      <div class="col text-start nowrap">
        <label class="fw-bold">
          {{ $t('children') }}
        </label>
      </div>
      <div class="col text-end">
        <select
          class="form-select w-auto"
          v-model="productOptions.selectedChildrenPax"
        >
          <option
            v-for="(_, idx) in (minMaxChildrenParticipants.max + 1)"
            :key="idx"
            :value="idx"
          >
            {{ idx }}
          </option>
        </select>
      </div>
      <div class="col text-end">
        <span v-if="productOptions.selectedChildrenPax > 0">
          <span class="text-decoration-line-through me-2">
            {{ formatCurrency(getPrice(prices.prices, productOptions.selectedPax), props.currency) }}
          </span>
          <span class="text-success">
            -{{
              getChildDiscountPercent(
                getPrice(prices.prices, productOptions.selectedPax),
                getPrice(prices.children_prices, productOptions.selectedChildrenPax)
              )
            }}%
          </span>
        </span>
      </div>
      <div class="col text-end">
        <span v-if="productOptions.selectedChildrenPax > 0">
          {{ productOptions.selectedChildrenPax }} x {{ formatCurrency(getPrice(prices.children_prices, productOptions.selectedChildrenPax), props.currency) }}
        </span>
      </div>
      <div class="col text-end">
        <span v-if="productOptions.selectedChildrenPax > 0">
          {{ formatCurrency(getTotalLineItemPrice(prices.children_prices, productOptions.selectedChildrenPax), props.currency) }}
        </span>
      </div>
    </div>
  </div>

  <div class="row mt-3 align-items-center">
    <h6 class="border-top mt-2 pt-4 fw-bold d-flex justify-content-between">
      <span>{{ $t('total') }}</span>
      <span>{{ formatCurrency(calculateGrossProfit(), props.currency) }}</span>
    </h6>
  </div>
</template>

<script lang="ts" setup>
import { formatCurrency } from '@/helpers/parseCurrency';
import { PricesData } from '@/types/prices';

const emit = defineEmits(['update:grossProfit']);

const props = defineProps<{
  currency: string | undefined;
  minMaxParticipants: { min: number; max: number };
  minMaxChildrenParticipants: { min: number; max: number };
  prices: PricesData;
}>();

const productOptions = defineModel('productOptions', { default: { selectedPax: 0, selectedChildrenPax: 0 } });

// get the price based on the selected number of participants and children
const getPrice = (prices: any, pax: number) => {
  if (!prices) return 0

  return prices.find((price: any) => pax >= price.min_participants && pax <= price.max_participants)?.selling_price;
}

const getTotalLineItemPrice = (prices: any, pax: number) => {
  if (!prices || pax === 0) return 0;

  return (pax * getPrice(prices, pax));
}

const calculateGrossProfit = () => {
  const grossProfit = getTotalLineItemPrice(props.prices.prices, productOptions.value.selectedPax) + getTotalLineItemPrice(props.prices.children_prices, productOptions.value.selectedChildrenPax);
  emit('update:grossProfit', grossProfit);
  return grossProfit;
}

const getChildDiscountPercent = (price: number, childPrice: number) => {
  return Math.round(((price - childPrice) / price) * 100);
}
</script>

<style scoped>
.tour-selection-container {
  .col.text-start {
    min-width: 120px;
  }
}
</style>
