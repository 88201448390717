<template>
  <TopBar />

  <main>
    <div class="banner w-100">
      <img 
        src="@/assets/images/not_found_banner.png" 
        alt="Inner banner" 
        class="w-100 h-100 object-fit-cover"
      />
    </div>

    <div class="container py-5 d-flex flex-column align-items-center text-center">
      <img
        src="@/assets/images/not_found_icon.svg"
        alt="Page not found"
      />

      <div class="mt-5">
        <h1 class="mb-3 fw-bold fs-3 lh-base text-dark"> 
          {{ $t('page_not_found') }}
        </h1>
        <p
          class="fs-6 fw-normal text-dark"
          v-html="$t('page_not_found_text')"
        >
        </p>
      </div>

      <a :href="homeUrl" class="btn rounded-3 text-center text-white mt-4 text-decoration-none fs-6 fw-semibold lh-sm" >
        {{ $t('go_back') }}
      </a>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useLocaleStore } from "@/stores/localeStore";

import TopBar from "@/components/TopBar.vue";

const props = defineProps<{
  locale: string,
  topSlider: any,
}>();

const localestore = useLocaleStore();
localestore.setLocale(props.locale);

const homeUrl = computed(() => `${window.location.origin}/${props.locale}/`);

</script>

<style scoped>
.banner {
  height: 260px;
}

.btn {
  padding: 14px 24px 14px 24px;
  background: #00AB3C;

  &:hover {
    background: #008930;
  }
}
</style>