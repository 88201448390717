<template>
  <TopBar />

  <main class="d-flex flex-column p-2 p-md-4">
    <div class="container my-4 flex-grow-1">
      <h1 class="fw-bold fs-2">{{ $t('phone_page.title') }}</h1>

      <div v-if="!isSubmitSuccessful && !isSubmitFailed">
        <div class="mb-4">
          <p>{{ $t('phone_page.subtitle') }}</p>
          <p>{{ $t('phone_page.note') }}</p>
        </div>

        <div class="col-sm-6 col-lg-4 col-xl-3">
          <VueTelInput 
            v-bind="phoneOptions" 
            @blur="handlePhoneBlur"
            @validate="handlePhoneValidation" 
            class="form-control d-flex phone-input p-0 rounded-1"
            :class="{
              'is-invalid': errors.phone,
              'is-valid': !errors.phone && customerPhone
            }"
            v-model="customerPhone" 
          />

          <div v-if="errors.phone" class="text-danger mt-1">
            <span>{{ $t('phone_error_message') }}</span>
          </div>

          <div class="text-center my-4">
            <button 
              class="btn btn-success px-4 py-2 rounded-3" 
              :disabled="!customerPhone"
              type="submit" 
              @click="handlePhoneSubmit"
              >
               {{ $t('send') }}
            </button>
          </div>
        </div>
      </div>

      <div v-else>
        <p class="fs-5 fw-bold" :class="isSubmitSuccessful ? 'text-success' : 'text-danger'">
          {{ isSubmitSuccessful ? $t('phone_page.success_subtitle') : $t('phone_page.error_subtitle') }}
        </p>
        <p>
          {{ isSubmitSuccessful ? $t('phone_page.success_note') : $t('phone_page.error_note') }}
        </p>
      </div>
    </div>

    <MediaPartners :mediaPartners="mediaPartners" />
  </main>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useLocaleStore } from "@/stores/localeStore";
import { VueTelInput } from 'vue-tel-input';

import MediaPartners from "@/components/MediaPartners.vue";
import TopBar from '@/components/TopBar.vue';

import { usePhoneSubmission } from "@/composables/usePhoneSubmission";

const props = defineProps<{
  locale: string,
  mediaPartners: {
    postTitle: string,
    postImage: string,
  }[],
}>();

const localestore = useLocaleStore();
localestore.setLocale(props.locale);

const token = new URLSearchParams(window.location.search).get('token');

const customerPhone = ref('');
const isPhoneValid = ref(false);
const errors = ref({ phone: false });

const phoneOptions = {
  mode: "international",
  preferredCountries: ["nl", "de", "da", "gb", "us"],
  autoDefaultCountry: true,
  validCharactersOnly: true,
  dropdownOptions: {
    showFlags: true,
    showDialCodeInSelection: true,
    showSearchBox: false,
  },
  inputOptions: {
    placeholder: "1234567890",
  },
};

const { isSubmitSuccessful, isSubmitFailed, submitPhoneNumber } = usePhoneSubmission();

const handlePhoneValidation = (phoneObject: any) => {
  isPhoneValid.value = phoneObject.valid;

  if (phoneObject.valid) {
    customerPhone.value = phoneObject.number;
  }
};

const handlePhoneBlur = () => {
  errors.value.phone = !isPhoneValid.value;
};

const handlePhoneSubmit = () => {
  submitPhoneNumber(token, customerPhone.value, isPhoneValid.value, errors.value);
};
</script>

<style scoped>
main {
  min-height: 75vh;
}
</style>
