/**
 * Splits a time string into an object with time, overall capacity, adult capacity, and child capacity.
 * @param time - The time string to split.
 * @returns An object with time, overall capacity, adult capacity, and child capacity, or null if the time string is invalid.
 *
 * bb3      : 10:00
 * ventrata : 10:00|[overallCapacity]
 * farharbor: 10:00|[overallCapacity]|[adultCapacity]|[childCapacity]
 */
export const parseTimeslot = (time: string) => {
  const regex = /^-?(\d{1,2}:\d{2})-?(?:\|(\d+)(?:\|(\d+))?(?:\|(\d+))?)?$/;

  const match = time.match(regex);

  if (!match) return { time: '' };

  return {
    time: match[1],
    overallCapacity: parseInt(match[2]),
    adultCapacity: parseInt(match[3]),
    childCapacity: parseInt(match[4]),
  };
}