<template>
  <TopBar :isHomePage="true" />

  <TheHeader
    :tabs="tabs"
    :activeTab="activeTab"
    @tabChange="setActiveTab"
  />
  <main>
    <!-- Tab Content -->
    <section class="tab-content container my-4">
      <div
        v-for="tab in tabs"
        :key="tab.name"
        :class="['tab-pane', { active: activeTab === tab.name }]"
      >
        <TopBikeTours :items="tab.items" />
      </div>
    </section>

    <div class="container my-4">
      <TopDestinations :items="topDestinationsBlock.cities" :viewAll="topDestinationsBlock.view_all" />

      <PromotionalBlocks :top-promotions-block="topPromotionsBlock" />

      <HomePageMainContent :mainContent="mainContent"/>

      <MediaPartners :mediaPartners="mediaPartners" />
    </div>
  </main>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useLocaleStore } from "@/stores/localeStore";

import type { TourData } from "@/types/top-bike-tour";
import type { CityData } from "@/types/city";
import type { Tab }      from "@/types/tab";

import WalkingToursIcon    from "@/components/Icons/WalkingToursIcon.vue";
import DestinationsIcon    from "@/components/Icons/Destinations.vue";
import BikeToursIcon       from "@/components/Icons/BikeTours.vue";
import BikeRentalsIcon     from "@/components/Icons/BikeRentals.vue";
import TheHeader           from "@/components/TheHeader.vue";
import TopBikeTours        from "@/components/TopBikeTours.vue";
import TopDestinations     from "@/components/TopDestinations.vue";
import PromotionalBlocks   from "@/components/PromotionalBlocks.vue";
import TopBar              from "@/components/TopBar.vue";
import HomePageMainContent from "@/components/HomePageMainContent.vue";
import MediaPartners       from "@/components/MediaPartners.vue";

const props = defineProps<{
  title: string,
  description: string,
  mainContent: string,
  mobile_title: string,
  section_sub_title: string,

  locale: string,
  topProductsBlock: {
    products: TourData[],
  },
  topBikeToursBlock: {
    products: TourData[],
  },
  topRentalsBlock: {
    products: TourData[],
  },
  topDestinationsBlock: {
    view_all: any,
    cities: CityData[],
  }
  topPromotionsBlock: {
    info: any,
    blocks: any,
  },
  mediaPartners: {
    postTitle: string,
    postImage: string,
  }[],
}>();

const localestore = useLocaleStore();
localestore.setLocale(props.locale);

// Tab handling
// Active tab state
const activeTab = ref("TopBikeTours");

// Tabs configuration
const tabs: Tab[] = [
  { name: "TopBikeTours", label: "Top bike tours", icon: DestinationsIcon, items: props.topProductsBlock.products || [] },
  { name: "BikeTours", label: "Bike Tours", icon: BikeToursIcon, items: props.topBikeToursBlock.products || [] },
  { name: "BikeRental", label: "Bike Rental", icon: BikeRentalsIcon, items: props.topRentalsBlock.products || [] },
  // TODO: Add walking tours
  { name: "WalkingTours", label: "Walking Tours", icon: WalkingToursIcon, items: [] },
];

// Update active tab when tab is clicked
const setActiveTab = (tabName: string) => {
  activeTab.value = tabName;
};
// End Tab handling
</script>

<style scoped>
</style>
