<template>
  <footer v-if="!isLoading" class="footer text-dark py-3">
    <div class="container-xl">
      <!-- Footer Content -->
      <div class="row px-3">
        <div
          v-for="(widget, index) in footerMenu" :key="index"
          class="footer-widget col-12 border-top fs-7"
          :class="!(widget as FooterWidget).has_social_media ? 'col-lg' : ''"
        >
          <div
            v-if="!(widget as FooterWidget).has_social_media"
            class="widget-title d-flex justify-content-between align-items-center py-3 fw-bold"
            @click="toggleWidget(index)"
            role="button"
          >
            {{ parsedWidgets[index].title }}
            <Icon
              name="chevron-down"
              customClass="d-lg-none"
              :class="{'rotate-180': openWidgets[index]}"
              size="lg"
            />
          </div>
          <div
            :class="[
              'widget-content collapse-transition',
              {'collapsed': !openWidgets[index] && !(widget as FooterWidget).has_social_media}
            ]"
          >
            <div
              class="menu-columns"
              v-if="!(widget as FooterWidget).has_social_media"
              v-html="parsedWidgets[index].content"
            ></div>

            <div v-else class="row">
              <div
                v-if="parsedWidgets[index]?.aTags && parsedWidgets[index].aTags.length > 0"
                class="last-column text-lg-start py-3 px-0 mt-3 mt-lg-0 col-lg-auto"
              >
                <div
                  v-for="(aTag, i) in parsedWidgets[index].aTags"
                  :key="i"
                  class="py-2 d-lg-inline-block"
                >
                  <a :href="aTag.href" class="px-2 fs-7">{{ aTag.textContent }}</a>
                </div>
              </div>
              <div class="social-icons d-flex justify-content-center justify-content-lg-end gap-4 py-3 col-lg">
                <template
                  v-for="(socialMedia, index) in ((socialMediaWidget as unknown) as FooterWidget)?.social_media ?? {}"
                  :key="index"
                >
                  <a
                    v-if="socialMedia.link"
                    :href="socialMedia.link"
                    class="text-dark"
                  >
                    <Icon type="fab" :name="index" customClass="brand-icon fa-xl py-2"/>
                  </a>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">Switch back to <a href="/wp/wp-admin/themes.php">the old theme</a>, this one is in development</div>
  </footer>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useLocaleStore } from '@/stores/localeStore';

interface SocialMedia {
  link: string;
}

interface FooterWidget {
  has_social_media?: boolean;
  widget_content: string;
  social_media?: Record<string, SocialMedia>;
}

const localeStore = useLocaleStore();
const openWidgets = ref<boolean[]>([]);
const footerMenu  = computed(() => localeStore.footerMenu);
const isLoading   = computed(() => localeStore.isFooterLoading);

onMounted(async () => {
  await localeStore.fetchFooterData();
  openWidgets.value = Array(localeStore.footerMenu.length).fill(false);
});

const toggleWidget = (index: number) => {
  openWidgets.value[index] = !openWidgets.value[index];
};

const socialMediaWidget = computed(() =>
  footerMenu.value.find((widget: FooterWidget) => widget.has_social_media)
);

const parseWidgetContent = (widget: any) => {
  const parser       = new DOMParser();
  const doc          = parser.parseFromString(widget.widget_content, 'text/html');
  const titleElement = doc.querySelector('.widget-title');
  const title        = titleElement?.textContent?.trim() || '';

  // Remove the title element from content
  titleElement?.remove();

  // Get the remaining content
  if (widget.has_social_media) {
    const aTags = doc.querySelectorAll('a');
    return { title, aTags };
  }
  const content = doc.querySelector('.widget-content')?.innerHTML || '';

  return { title, content };
};

const parsedWidgets = computed(() =>
  footerMenu.value.map((widget: any) => parseWidgetContent(widget))
);
</script>

<style scoped>
.footer {
  background-color: #F6F6F6;

  .footer-widget {
    &:not(:last-child) {
      @media (min-width: 992px) {
        border-top: none !important;
      }
    }

    .widget-title {
      color: #252525;
      cursor: pointer;
      user-select: none;

      .rotate-180 {
        transform: rotate(180deg);
      }
    }

    .widget-content {
      :deep(img) {
        display: none;
      }

      :deep(a) {
        color: #717171;
      }

      :deep(:not(.last-column a)) {
        text-decoration: none;
      }

      :deep(p > a) {
        display: inline-block;
        padding-bottom: 16px;
      }

      .menu-columns {
        :deep(a) {
          color: green;
        }
      }

      .social-icons {
        .brand-icon {
          color: #717171;
        }
      }

      @media (max-width: 991px) {
        &.collapse-transition {
        display: grid;
        grid-template-rows: 1fr;
        transition: grid-template-rows 0.3s ease-in-out, opacity 0.3s ease-in-out;
        opacity: 1;

        &.collapsed {
          grid-template-rows: 0fr;
          opacity: 0;
        }

        > * {
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
