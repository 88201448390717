import { PricesData } from '@/types/prices';
import { ref } from 'vue';

export function useProductPrices() {
  const loading = ref(false);
  const error = ref<string | null>(null);

  const prices = ref<PricesData>({
    prices: [],
    children_prices: [],
  });

  const fetchPrices = async (productId: string, language: string, date: string, time: string) => {
    if (!productId) return;

    const apiUrl = `${import.meta.env.VITE_PRICES_API_URL}/${productId}?language=${language}&date=${date}T${time}&raw=true`;
    loading.value = true;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch prices[${productId}]: ${response.statusText}`);
      }

      const data = await response.json();
      prices.value = data.data;
    } catch (err) {
      error.value = err instanceof Error ? err.message : String(err);
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  return { loading, error, fetchPrices, prices };
}