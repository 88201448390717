import { ref } from 'vue';

export function useFeedback() {
  const isSubmitSuccessful = ref(false);
  const isSubmitFailed = ref(false);

  const submitFeedback = async (token: string | null, customerFeedback: string, isInputValid: boolean, nonceToken: string | null, feedbackType: string | null, errors: any) => {
    if (!isInputValid) {
      errors.input = true;
      return;
    }

    try {
      const response = await fetch(`${window.location.origin}/wp-json/bajabikes/v1/send-feedback/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          feedback: customerFeedback,
          nonce_token: nonceToken,
          feedback_type: feedbackType,
        }),
      });

      if (response.ok) {
        isSubmitSuccessful.value = true;
      } else {
        isSubmitFailed.value = true;
      }
    } catch (error) {
      console.error("Error:", error);
      isSubmitFailed.value = true;
    }
  };

  return {
    isSubmitSuccessful,
    isSubmitFailed,
    submitFeedback,
  };
}
