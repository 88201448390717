<template>
  <div>
    <h2>Bike Rental placeholder</h2>
    <div>
      <div>
        <h3>Price</h3>
        <p>{{ price }}</p>
      </div>
      <div>
        <h3>Duration</h3>
        <p>{{ duration }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  productId: string;
  price: string;
  priceChildren: string;
  duration: string;
}>();
</script>

<style scoped>
</style>