import { useLocaleStore } from "@/stores/localeStore";

export const formatCurrency = (amount: number, currency = 'EUR') => {
  const { locale } = useLocaleStore();
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
}