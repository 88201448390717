<template>
  <div class="position-relative pt-3 mb-5 d-flex flex-column align-items-center"
    ref="spotlightRef"
  >
    <!-- Search Bar -->
    <div
      class="search-bar d-flex align-items-center rounded-pill px-3 py-2 shadow bg-white position-relative"
    >
      <Icon name="search" customClass="me-2"/>
      <input
        type="text"
        class="form-control border-0 shadow-none p-0"
        :placeholder="$t('search')"
        v-model="searchQuery"
        @focus="showResults = true"
        ref="searchInputRefDesktop"
      />
      <button
        class="btn btn-close ms-2"
        v-if="searchQuery"
        @click="clearSearch"
      ></button>
    </div>

    <!-- Search Results -->
    <div v-if="showResults && !isMobile && hasResults" class="search-results position-absolute bg-white shadow rounded mt-1 overflow-auto">
      <Results
        :results="results"
        ref="resultsRef"
      />
    </div>

    <!-- Full-Screen Search Overlay -->
    <div
      v-if="showResults && isMobile"
      class="search-overlay position-fixed start-0 w-100 h-100 bg-white overflow-auto rounded-top"
      style="z-index: 1050;"
      ref="spotlightRefMobile"
    >
      <!-- Header -->
      <div class="overlay-header d-flex align-items-center justify-content-between px-3 py-2 shadow-sm border-bottom">
        <button class="btn btn-close p-0" @click="closeResults"></button>
        <h6 class="text-center m-0 flex-grow-1"> {{ $t('search') }} </h6>
      </div>

      <!-- Search Bar (in overlay) -->
      <div class="px-3 py-3">
        <div class="search-bar-overlay rounded-pill px-3 py-2 bg-light d-flex align-items-center">
          <Icon name="search" customClass="me-2"/>
          <input
            type="text"
            class="form-control border-0 shadow-none p-0 bg-transparent"
            :placeholder="$t('search')"
            v-model="searchQuery"
            ref="searchInputRefMobile"
          />
          <button
            @click="clearSearch"
            class="clear-search btn btn-sm position-absolute end-0 me-4 rounded-circle border border-dark text-dark"
            :class="{ 'invisible': !searchQuery }"
          >
            X
          </button>
        </div>
      </div>

      <!-- Search Results -->
      <Results
        :results="results"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { useMobile } from "@/composables/useMobile";
import { useSpotlight } from "@/composables/useSpotlight";
import { useLocaleStore } from "@/stores/localeStore";

import Results from "@/components/Spotlight/Results.vue";

const searchQuery = ref("");
const showResults = ref(false);
const debouncedQuery = ref("");
const searchInputRefDesktop = ref<HTMLInputElement | null>(null);
const searchInputRefMobile = ref<HTMLInputElement | null>(null);
const spotlightRef = ref<HTMLElement | null>(null);
const spotlightRefMobile = ref<HTMLElement | null>(null);
const resultsRef = ref<HTMLElement | null>(null);

const { locale } = useLocaleStore();
const { isMobile } = useMobile();

const clearSearch = () => {
  searchInputRefMobile.value?.focus();
  searchQuery.value = "";
};

const closeResults = () => {
  searchQuery.value = "";
  showResults.value = false;
  clearResults();
};

const clearResults = () => {
  results.value = { cities: [], countries: [], tours: [] };
};

const hasResults = computed(() => {
  if (!results.value) return false;
  return Object.values(results.value).some(group => group && group.length > 0);
});

// spotlight search
const { loading, error, fetchSpotlight, results } = useSpotlight();

let debounceTimeout: ReturnType<typeof setTimeout> | null = null;
watch(searchQuery, (newVal) => {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }
  debounceTimeout = setTimeout(() => {
    debouncedQuery.value = newVal;
    if (debouncedQuery.value === "") {
      clearResults();
    } else {
      fetchSpotlight(debouncedQuery.value, locale);
    }
  }, 300);
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    closeResults();
  }
};

const handleClickOutside = (event: MouseEvent) => {
  const clickedElement = event.target as Node;

  // desktop
  if (spotlightRef.value && spotlightRef.value.contains(clickedElement)) {
    return; // Click is inside the search bar area, do nothing
  }
  if (
    resultsRef.value &&
    (resultsRef.value as any).$el &&
    (resultsRef.value as any).$el.contains(clickedElement)
  ) {
    return; // Click is inside the results popup, do nothing
  }

  // mobile
  if (spotlightRefMobile.value && spotlightRefMobile.value.contains(clickedElement)) {
    return; // Click is inside the search bar area, do nothing
  }
  if (spotlightRef.value && spotlightRef.value.contains(clickedElement)) {
    return; // Click is inside the search bar area, do nothing
  }
  closeResults();
};

watch(showResults, async (newVal: boolean) => {
  if (newVal) {
    await nextTick(); // Wait for the DOM update
    if (isMobile.value && searchInputRefMobile.value) {
      searchInputRefMobile.value.focus();
    }
  }
});

onMounted(() => {
  window.addEventListener("keydown", handleKeydown);
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeydown);
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style scoped>
.search-bar {
  width: 90%;
  max-width: 600px;
}

.search-results {
  border-radius: 20px !important;
  padding-top: 20px;
  width: 600px;
  max-height: 650px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);

  overflow-y: scroll; /* Enables scrolling */
  -ms-overflow-style: none; /* Hides the scrollbar in Internet Explorer and Edge */
  scrollbar-width: none; /* Hides the scrollbar in Firefox */

  ::-webkit-scrollbar {
    display: none; /* Hides the scrollbar in Webkit-based browsers (Chrome, Safari, etc.) */
  }
}

.search-overlay {
  animation: fadeIn 0.3s ease-in-out;
}

.clear-search {
  font-size: 9px;
}

.overlay-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1051;
}

.search-bar-overlay {
  width: 100%;
  border: 1px solid #ddd;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
