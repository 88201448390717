<template>
  <TopBar />

  <main class="d-flex flex-column p-2 p-md-4">
    <div class="container my-4 flex-grow-1">
      <h1 class="fw-bold fs-2">{{ $t('feedback.title') }}</h1>
      <p v-if="feedbackType === 'not-nonshow'">
        {{ $t('feedback.feedback_request_1') }}
        <br />
        {{ $t('feedback.feedback_request_2') }}
      </p>
      <p v-else>{{ $t('feedback.nonshow_feedback_request') }}</p>
      <p>{{ $t('feedback.your_comment') }}</p>

      <div class="col-12 col-md-8 col-lg-6">
        <textarea 
            id="feedback" 
            name="feedback" 
            rows="5"
            v-model.trim="customerFeedback"
            class="form-control"
            :class="{
              'is-invalid': isSubmitted && !customerFeedback,
              'is-valid': isSubmitted && customerFeedback
            }"
          >
          </textarea>

          <div v-if="isSubmitted && !customerFeedback" class="text-danger mt-1">
            <span>{{ $t('fill_required_message') }}</span>
          </div>

          <div class="text-center my-4">
            <button
              class="btn btn-success px-4 py-2 rounded-3" 
              type="submit"
              @click="handleFeedbackSubmit"
              >
               {{ $t('send') }}
            </button>
          </div>
        </div>
    </div>

    <MediaPartners :mediaPartners="mediaPartners" />
  </main>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useLocaleStore } from "@/stores/localeStore";

import MediaPartners from "@/components/MediaPartners.vue";
import TopBar from '@/components/TopBar.vue';

import { useFeedback } from "@/composables/useFeedback";

const props = defineProps<{
  locale: string,
  mediaPartners: {
    postTitle: string,
    postImage: string,
  }[],
  feedbackType: string,
  nonceToken: string
}>();

const localestore = useLocaleStore();
localestore.setLocale(props.locale);

const token = new URLSearchParams(window.location.search).get('t');

const customerFeedback = ref<string>('');
const isSubmitted = ref<boolean>(false);
const isInputValid = ref<boolean>(false);
const errors = ref({ input: false });

const { isSubmitSuccessful, isSubmitFailed, submitFeedback } = useFeedback();

const handleInputValidation = () => {
  if (!customerFeedback.value) {
    isInputValid.value = false;
  } else {
    isInputValid.value = true;
  }
};

const handleFeedbackSubmit = () => {
  isSubmitted.value = true;
  handleInputValidation(); 

  submitFeedback(token, customerFeedback.value, isInputValid.value, props.nonceToken, props.feedbackType, errors.value);
};
</script>

<style scoped>
main {
  min-height: 75vh;
}
</style>
