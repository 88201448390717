<template>
  <div>
    <h1>Search results (Coming Soon)</h1>
    <h2>This page is under construction.</h2>
  </div>
  <div>
    <p>
      query: {{ props.query }}
    </p>
    <p>
      query params: {{ queryParams }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';


const props = defineProps<{
  query: string,
  locale: string,
}>();

const queryParams = ref({});

onMounted(() => {
  const searchParams = new URLSearchParams(window.location.search);
  queryParams.value = Object.fromEntries(searchParams.entries());
});

</script>

<style>
h1 {
  color: blue;
}
</style>
