<template>
  <div class="card-container">
    <a
      class="card overflow-hidden d-flex flex-column h-100"
      :href="permalink"
    >
      <picture>
        <source v-if="localImage !== tourImage.origImg" type="image/avif" :srcset="tourImage.imgAvif">
        <source v-if="localImage !== tourImage.origImg" type="image/webp" :srcset="tourImage.imgWebp">
        <img
          :alt="title"
          :src="localImage"
          @error="handleError"
          width="100%"
          loading="lazy"
        >
      </picture>

      <div class="card-body d-flex flex-column justify-content-around">
        <p class="card-text text-muted small my-0">{{ duration }}</p>
        <h6
          :title="title"
          class="card-title fw-bold mb-0 text-truncate">
          {{ title }}
        </h6>
        <ReviewStars
          :average="rating"
          :total="reviews"
          :with-link="false"
        />
        <span class="price fw-bold">{{ price }}</span>
      </div>
    </a>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import ReviewStars from './ReviewStars.vue';

const props = defineProps<{
  title: string,
  duration: string,
  price: string,
  permalink: string,
  rating?: number,
  reviews?: number,
  tourImage: {
    imgAvif: string,
    imgWebp: string,
    imgJpg: string,
    origImg: string,
  },
}>();

const localImage = ref(props.tourImage.imgJpg);

const handleError = () => {
  localImage.value = props.tourImage.origImg;
}
</script>

<style scoped>
.card-container {
  height: 400px;
}

.card {
  margin: 0 12px;
  text-decoration: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  }

  img {
    height: 240px;
    object-fit: cover;
  }

  .text-muted {
    color: #474747 !important;
  }
}
</style>