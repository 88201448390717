<template>
  <div class="the-header position-relative pt-5">
    <div class="background-image"></div>
    <div class="position-relative z-2 pt-4">
      <div class="text-center text-light pt-4 px-3 d-none d-md-block">
        <p class="fs-1 fw-bold">The best bike tours</p>
      </div>

      <!-- Search Bar -->
      <Spotlight />

      <!-- Header Text -->
      <div class="text-center text-light mt-4 px-3 pb-4 d-md-none">
        <p class="fs-1 fw-bold">The world is at your wheels</p>
        <p class="fs-5">200 destinations with the best guided bike tours and bike rental</p>
      </div>

      <!-- Tabs -->
      <div class="tabs-container d-flex justify-content-around px-2 px-md-4 pt-3 container my-4">
        <button
          v-for="tab in tabs"
          :key="tab.name"
          @click="changeTab(tab.name)"
          class="tabs btn flex-grow-1 text-light d-flex align-items-center justify-content-center gap-1 fw-bold"
          :class="{
            'btn-primary text-secondary bg-light': activeTab === tab.name,
            'btn-light': activeTab !== tab.name,
            'flex-column': !isWideScreen,
            'flex-row': isWideScreen,
            'd-none': isMobile && tab.name === 'WalkingTours',
          }"
        >
          <component :is="tab.icon" class="icon" :color="'#fff'" />
          <span
            class="d-block mt-1"
            :class="{'mt-1': !isWideScreen}"
            >{{ tab.label }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useMobile } from "@/composables/useMobile";
import Spotlight from "@/components/Spotlight/Spotlight.vue";

import type { Tab } from "@/types/tab";

const props = defineProps<{
  tabs: Tab[];
  activeTab: string;
}>();

const emit = defineEmits(["tabChange"]);

// Handle tab change
const changeTab = (tabName: string) => {
  emit("tabChange", tabName);
};

const { isMobile, isWideScreen } = useMobile();
</script>

<style scoped>
.tabs-container {
  background-color: transparent;
  border: none;

  .tabs {
    border: none;
    background-color: transparent;
  }
}

.banner {
  position: relative;
  background: transparent;
  color: #fff;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/images/mobile-front-banner.avif');
  background-size: cover;
  background-position: center;
}

.background-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 99%);

  z-index: 2;
}

.content-wrapper {
  position: relative;
  z-index: 2;
}

.tabs {
  border-radius: 8px 8px 0 0;
}
</style>