<template>
  <div>
    <h1>This is a general INDEX page VUE template.</h1>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Index',
};
</script>

<style>
h1 {
  color: blue;
}
</style>
