<template>
  <div class="date-and-time">
    <div class="months row">
      <!-- Left Calendar -->
      <div :class="['col-12', showDualCalendar ? 'col-md-6' : '']">
        <div class="month d-flex align-items-center">
          <Icon
            @click="previousMonth"
            type="far"
            name="fa-chevron-left"
            :customClass="addMonthForGrid === currentDate.getMonth() ? 'pe-none text-muted' : 'cursor-pointer'"
          />
          <h4 class="text-center flex-grow-1">{{ formattedMonth(addMonthForGrid) }}</h4>
          <Icon
            v-if="!showDualCalendar"
            @click="nextMonth"
            type="far"
            name="fa-chevron-right"
            customClass="cursor-pointer"
          />
        </div>
        <div class="weekdays d-grid text-center">
          <div v-for="day in daysOfTheWeek" :key="day" class="grid-day header">
            {{ day }}
          </div>
        </div>
        <div class="grid-days">
          <div v-for="(row, index) in leftCalendar" :key="index" class="grid-row">
            <div
              v-for="(day, dayIndex) in row"
              :key="dayIndex"
              class="grid-day"
              :class="gridDayClass(day)"
              @click="setActiveDate(day)"
              :disabled="day.isDisabled"
            >
              <span v-if="day.date">{{ day.formatted }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Calendar -->
      <div v-if="showDualCalendar" class="col-md-6">
        <div class="month d-flex justify-content-between align-items-center">
          <h4 class="text-center flex-grow-1">{{ formattedMonth(addMonthForGrid + 1) }}</h4>
          <Icon
            @click="nextMonth"
            type="far"
            name="fa-chevron-right"
            customClass="cursor-pointer"
          />
        </div>
        <div class="weekdays d-grid text-center">
          <div v-for="day in daysOfTheWeek" :key="day" class="grid-day header">
            {{ day }}
          </div>
        </div>
        <div class="grid-days">
          <div v-for="(row, index) in rightCalendar" :key="index" class="grid-row">
            <div
              v-for="(day, dayIndex) in row"
              :key="dayIndex"
              class="grid-day"
              :class="gridDayClass(day)"
              @click="setActiveDate(day)"
              :disabled="day.isDisabled"
            >
              <span v-if="day.date">{{ day.formatted }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useLocaleStore } from '@/stores/localeStore';
import { useDates } from '@/composables/useDates';

const props = defineProps([
  'highlightedDates',
  'currentDate',
  'doubleCol',
  'dateAndTime'
]);
const emit = defineEmits(['selectedDate']);

const localeStore = useLocaleStore();
const { formatDate } = useDates();

const today = new Date();
today.setHours(0, 0, 0, 0);

const daysOfTheWeek = computed(() => {
  return Array.from({ length: 7 }, (_, i) =>
    new Intl.DateTimeFormat(localeStore.locale, { weekday: 'narrow' }).format(new Date(2023, 0, i + 1))
  );
});

const addMonthForGrid = ref(today.getMonth());
const leftCalendar = ref<any[][]>([]);
const rightCalendar = ref<any[][]>([]);
const activeDate = ref('');

const formattedMonth = (offset: number) => {
  const date = new Date();
  date.setMonth(offset);
  return new Intl.DateTimeFormat(localeStore.locale, { month: 'long', year: 'numeric' }).format(date);
};

const createGrid = (offset: number) => {
  const date = new Date();

  date.setMonth(offset);
  date.setDate(1);
  const firstDay = date.getDay(); // Get starting day (0 = Sunday)
  const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

  let grid: any[][] = [];
  let row = Array(firstDay).fill({ date: '', formatted: '' });
  for (let day = 1; day <= daysInMonth; day++) {
    const fullDate = new Date(date.getFullYear(), date.getMonth(), day);
    row.push({ date: formatDate(fullDate), formatted: day });

    if (row.length === 7) {
      grid.push(row);
      row = [];
    }
  }

  // Ensure last row has exactly 7 columns
  while (row.length < 7) {
    row.push({ date: '', formatted: '' }); // Empty cells
  }
  grid.push(row);

  return grid;
};

const highlightRows = () => {
  leftCalendar.value.flat().forEach((day: any) => {
    setDayStatus(day);
  });

  if (showDualCalendar.value) {
    rightCalendar.value.flat().forEach((day: any) => {
      setDayStatus(day);
    });
  }
}

const setDayStatus = (day: any) => {
  // has timeslots
  if (!props.dateAndTime?.[day.date]) {
    return day.isDisabled = true;
  }

  // is in the past
  if (new Date(day.date) < today) {
    return day.isDisabled = true;
  }

  // is highlighted
  if (props.highlightedDates.includes(day.date)) {
    day.isHighlighted = true;

    // has only waiting lists
    if (props.dateAndTime && hasOnlyWaitingLists(props.dateAndTime[day.date])) {
      day.isWaitingList = true;
    }
  }
}

const updateGrids = () => {
  leftCalendar.value = createGrid(addMonthForGrid.value);
  rightCalendar.value = createGrid(addMonthForGrid.value + 1);
  highlightRows();
};

const gridDayClass = (day: any) => {
  if (!day.date) return 'empty'; // For empty placeholders

  if (day.date === activeDate.value) {
    return 'active';
  }

  if (day.isDisabled) {
    return 'inactive text-muted';
  }

  if (day.isWaitingList) {
    return 'waiting-list';
  }

  if (day.isHighlighted) {
    return 'highlighted';
  }
};

const hasOnlyWaitingLists = (timeData: Record<string, string[]>) => {
  const timeslots = Object.entries(timeData);
  let langWithWaitingListCount = 0;

  // go through the array and check if all of the elements match the waiting list pattern
  timeslots.forEach(([lang, times]) => {
    if (times.every((time: string) => matchesWaitingList(time))) {
      langWithWaitingListCount++;
    }
  });

  // if all of the elements match the waiting list pattern, return true
  return langWithWaitingListCount === timeslots.length;
}

const matchesWaitingList = (time: string) => {
  return time.match(/-\d{1,2}:\d{1,2}-/);
}

const nextMonth = () => {
  addMonthForGrid.value++;
  updateGrids();
};

const previousMonth = () => {
  if (addMonthForGrid.value > 0) {
    addMonthForGrid.value--;
    updateGrids();
  }
};

const setActiveDate = (day: any) => {
  if (day.date && !day.isDisabled) {
    activeDate.value = day.date;
    emit('selectedDate', day.date, day.isWaitingList);
  }
};

// width > 992 show dual calendar
const width = ref(window.innerWidth);
const showDualCalendar = computed(() => width.value > 991);

const updateWidth = () => {
  width.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', updateWidth);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateWidth);
});

watch([() => props.highlightedDates, () => showDualCalendar.value], updateGrids);

onMounted(updateGrids);
</script>

<style scoped>
.date-and-time {
  .grid-day {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 4px;

    &[disabled=true] {
      cursor: default;
    }
  }

  .weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 8px 0;
  }

  .grid-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
  }

  .grid-day {
    &.empty {
      background-color: #f9f9f9;
    }

    &.highlighted {
      background-color: #00AB3C;
      color: white;

      &:hover {
        background-color: #008930;
      }
    }

    &.active {
      background-color: #2C508E;
      color: white;
    }

    &.inactive {
      background-color: #f9f9f9;
      text-decoration: line-through;
    }

    &.waiting-list {
      background-color: #FFA500;

      &:hover {
        background-color: #F98600;
      }
    }
  }

  .grid-row {
    display: contents;
  }

  .btn-link i {
    color: #1C1B1F;
  }
}
</style>
