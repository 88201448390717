<template>
  <div class="banner position-relative z-1">
    <div class="banner-image position-absolute bottom-0 w-100"></div>
    <img 
      class="w-100 h-100 object-fit-cover" 
      :src="topSlider" 
      alt="Slider image" 
    />

    <div class="position-absolute bottom-0 w-100">
      <h1 class="text-center text-white">
        {{ mainTitle }}
      </h1>
      <Spotlight />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Spotlight from "@/components/Spotlight/Spotlight.vue";

defineProps<{
  mainTitle: string;
  topSlider: any;
}>();

</script>

<style scoped>
.banner {
  height: 400px;
}

.banner-image {
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}
</style>