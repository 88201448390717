<template>
  <!-- Top Bar -->
  <div
    class="d-flex justify-content-between align-items-center"
    :class="{
      'p-2': !isHomePage,
      'p-3 position-absolute w-100 z-3': isHomePage,
    }"
    :style="{
      'background-color': isHomePage ? 'transparent' : '#FFD141'
    }"
  >
    <!-- Logo -->
    <div class="d-flex align-items-center">
      <a :href="homeUrl">
        <img
          :src="logoImage"
          alt="Baja Bikes"
          class="logo" />
      </a>
    </div>

    <!-- Language & Profile -->
    <div class="d-flex align-items-center">
      <LanguageSwitcher />

      <button
        class="btn btn-link text-light"
        aria-label="Profile"
      >
        <Icon type="fas" name="user" :style="{ color: '#2d508e' }"/>
      </button>
      <button
        class="btn btn-link d-lg-none text-light"
        @click="toggleMenu"
        aria-label="Toggle Menu"
      >
        <Icon type="fas" name="bars" :style="{ color: '#2d508e' }"/>
      </button>
    </div>

    <!-- Mobile Menu -->
    <div
      v-if="isMenuOpen"
      class="mobile-menu bg-light position-fixed top-0 start-0 w-75 vh-100 d-lg-none"
      aria-label="Mobile Menu"
    >
      <div class="d-flex">
        <button class="btn btn-link text-dark ms-auto" @click="closeMenu" aria-label="Close Menu">
          <Icon type="fas" name="times" :style="{ color: '#2d508e' }"/>
        </button>
      </div>
      <div class="m-2" v-if="currentMenuLevel === 0">
        <div class="fw-bold px-2">Go to...</div>
        <ul class="list-unstyled mt-2">
            <li v-for="(item, index) in menuContents" :key="index">
            <button
              class="nav-link d-flex justify-content-between align-items-center w-100"
              @click="goToSubmenu(index)"
            >
              <span>
                {{ item.title }}
              </span>
              <Icon type="fas" name="chevron-right" :style="{ color: '#2d508e' }"/>
            </button>
          </li>
        </ul>
      </div>

      <div v-else>
        <div class="d-flex align-items-center pb-2 mb-3 px-2 border-bottom">
          <button class="btn text-dark mb-0 p-0 d-flex align-items-center" @click="goBack">
            <Icon type="fas" name="chevron-left" :style="{ color: '#2d508e' }"/>
          </button>
          <div class="fw-bold px-2">{{ menuContents[currentMenuIndex].title }}</div>
        </div>

        <div
          class="search-bar d-flex align-items-center rounded-pill bg-white border border-1 mx-2 px-2 py-1"
        >
          <input
            type="text"
            v-model="searchQueryInput"
            placeholder="Where are you going?"
            class="search-input border-0 shadow-none p-0 w-100"
            @keyup.enter="setSearchQuery"
          />
          <Icon
            type="fas"
            name="search"
            :style="{ color: '#fff' }"
            customClass="me-2 fa-search"
            @click="setSearchQuery"/>
        </div>

        <ul class="list-unstyled mt-2 mx-2 ps-2">
          <li
            v-for="(subitem, subIndex) in filteredCities"
            :key="subIndex"
            class="mb-3"
          >
            <a :href="subitem.url" class="submenu-link">{{ subitem.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from "vue";
import { useMenu } from "@/composables/useMenu";
import { useLocaleStore } from "@/stores/localeStore";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

import type { MenuContent } from '@/types/menu';

import yellowLogo from "@/assets/images/bb-logo-white-yellow.svg";
import blueLogo from "@/assets/images/bb-logo-white-blue.svg";

const props = withDefaults(defineProps<{
  isHomePage?: boolean;
}>(), {
  isHomePage: true,
});

const logoImage = computed(() => props.isHomePage ? yellowLogo : blueLogo);

// General menu
const isMenuOpen = ref(false);
const currentMenuLevel = ref(0); // 0 = Top level, 1 = Submenu
const currentMenuIndex = ref(0); // Index of the current menu at level 1

const toggleMenu = () => (isMenuOpen.value = !isMenuOpen.value);
const closeMenu = () => {
  isMenuOpen.value = false;
  goBack();
}
const goToSubmenu = (index: number) => {
  currentMenuIndex.value = index;
  currentMenuLevel.value = 1;
};
const goBack = () => {
  searchQueryInput.value = '';
  searchQuery.value = '';
  currentMenuLevel.value = 0;
  currentMenuIndex.value = 0;
};

// handle menu data
const { fetchMenu } = useMenu();
const menuContents = ref<MenuContent[]>([]);

const localeStore = useLocaleStore();
const locale = localeStore.locale;

const homeUrl = computed(() => `${window.location.origin}/${locale}/`);

onBeforeMount(async () => {
  const menuData = await fetchMenu(locale);
  menuContents.value = menuData.reduce((acc: any, category: any) => {
    acc.push(transformToTwoLevelJson(category)[0]);
    return acc;
  }, []);
});

const transformToTwoLevelJson = (data: any) => {
  // Get all top-level items (e.g., Bike Rental, Bike Tours, Destinations)
  const topLevelItems = data.filter((item: any) => item.parentId === "0");

  const cities = data.reduce((acc: any[], item: any) => {
    if (item.url !== "#") {
      acc.push(item);
    }

    return acc;
  }, []);

  // Map top-level items to include their cities
  return topLevelItems.map((parentItem:any) => ({
    ...parentItem,
    children: cities.sort((a: any, b: any) => a.title.localeCompare(b.title)),
  }));
}

// Search
const searchQueryInput = ref('');
const searchQuery = ref('');
const setSearchQuery = () => {
  searchQuery.value = searchQueryInput.value;
};

// Filtered cities based on search input
const filteredCities = computed(() => {
  const regex = new RegExp(`\\b${searchQuery.value}`, 'i');
  const destinations = menuContents.value[currentMenuIndex.value];
  return destinations.children.filter((city: any) => regex.test(city.title));
});
</script>

<style scoped>
.logo {
  height: 32px;
}

.mobile-menu {
  z-index: 1050;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;

  input:focus-visible {
    outline: none;
  }

  ul {
    overflow-y: auto;
    max-height: calc(100vh - 50px);
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .flags {
    object-fit: cover;
    overflow: hidden;
  }
}

.nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 8px;
  background: transparent;
  border: none;
  width: 100%;
  text-align: left;
}

.nav-link:focus {
  outline: none;
}

.submenu {
  margin-top: 8px;
}

.submenu-link {
  font-size: 14px;
  color: #333;
  text-decoration: none;
}

.submenu-link:hover {
  text-decoration: underline;
}

.fa-search {
  border-radius: 50%;
  padding: 7px;
  color: white;
  background: #00AB3C;
}

.search-bar:focus-within {
  border-color: #4F7DBB !important;
  border-width: 2px !important;
}

i {
  color: #2d508e;
}
</style>
