<template>
  <div class="language-menu">
    <div class="language-selector dropdown">
      <button
        class="btn fs-7 dropdown-toggle d-flex align-items-center px-0 px-sm-2"
        :class="{
          'open': isLanguageMenuOpen,
        }"
        type="button"
        id="languageDropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        @click="toggleLanguageMenu"
      >
        <img
          class="flags me-1"
          :src="getFlagUrl(activeLanguage?.code || '')"
          width="20px"
          height="20px"
        />
        <span class="d-none d-md-block">
          {{ activeLanguage?.native_name }}
          <Icon name="angle-down"/>
        </span>
      </button>
      <ul
        class="dropdown-menu"
        v-if="!isMobile"
        :class="{
          'd-block': isLanguageMenuOpen,
        }"
        aria-labelledby="languageDropdown"
      >
        <li
          v-for="(item, index) in siteLanguages"
          :key="index"
          class="dropdown-item py-1"
          :class="{
            'current': item.active,
          }"
        >
          <button
            class="nav-link w-100"
            @click="changeLanguage(item.url)"
          >
            <span class="d-flex align-items-center">
              <img
                class="flags"
                :src="getFlagUrl(item.code)"
                width="20px"
                height="20px"
              />
              {{ item.native_name }}
              <Icon v-if="item.active" type="far" name="check" customClass="ms-auto"/>
            </span>
          </button>
        </li>
      </ul>
    </div>
    <div v-if="isMobile">
      <div
        v-if="isLanguageMenuOpen"
        class="mobile-language-menu bg-white position-fixed top-0 start-0 w-75 vh-100 p-2 z-2"
      >
        <div class="d-flex justify-content-end">
          <button
            class="btn-close p-2 m-2 opacity-75"
            @click="toggleLanguageMenu"
            aria-label="Close Menu"
          >
          </button>
        </div>

        <div class="h4 px-3 fw-bold">
          {{ $t('change_language') }}
        </div>

        <ul class="list-unstyled mt-4">
          <li
            v-for="(item, index) in siteLanguages"
            :key="index"
            class="py-2 px-3 language-item"
            :class="{
              'current': item.active,
            }"
          >
            <button
              class="nav-link d-flex justify-content-between align-items-center w-100"
              @click="changeLanguage(item.url)"
            >
              <span>
                <img
                  class="flags"
                  :src="getFlagUrl(item.code)"
                  width="24px"
                  height="24px"
                />
                {{ item.native_name }}
              </span>
              <Icon v-if="item.active" type="far" name="check" customClass="ms-auto"/>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useLocaleStore } from '@/stores/localeStore';
import { useMobile } from "@/composables/useMobile";

interface LanguageItem {
  active: boolean;
  code: string;
  native_name: string;
  country_flag_url: string;
}

onMounted(() => {
  document.addEventListener('click', closeLanguageMenu);
});

onUnmounted(() => {
  document.removeEventListener('click', closeLanguageMenu);
});

const { isMobile } = useMobile();

const localeStore = useLocaleStore();

const siteLanguages = computed(() => {
  return localeStore.siteLanguages
});

const activeLanguage = ref(
  Object.values(siteLanguages.value as Record<string, LanguageItem>)
    .find((item: LanguageItem) => item.active)
);

const isLanguageMenuOpen = ref(false);

const toggleLanguageMenu = () => {
  return isLanguageMenuOpen.value = !isLanguageMenuOpen.value;
};

const closeLanguageMenu = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  if (!target.closest('.language-selector') && !target.closest('.mobile-language-menu')) {
    isLanguageMenuOpen.value = false;
  }
};

const changeLanguage = (url: string) => {
  window.location.href = url;
};

const flagImages = import.meta.glob(
  '@/assets/images/flag_circle_*.svg',
  { eager: true, query: '?url', import: 'default' }
);

const getFlagUrl = (code: string): string => {
  const flagPath = `/src/assets/images/flag_circle_${code}.svg`;
  return (flagImages[flagPath] as string) || '';
};

</script>

<style scoped>
.language-selector .dropdown-toggle:hover span,
.language-selector .dropdown-toggle.open span {
  color: #FFD141 !important;
}

.dropdown-toggle:after {
  content: none;
}

.dropdown-item.current,
.mobile-language-menu li.current {
  background-color: #F3F9FD;
}

.language-item {
  margin: 0 -8px;
}
</style>
