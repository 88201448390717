import { ref } from 'vue';

export function useEkomiReviews() {
  const reviews = ref<Record<string, any>>({});
  const loading = ref(false);
  const error = ref<string | null>(null);

  /**
   * Fetch reviews from eKomi for given product IDs.
   * @param productIds Array of product IDs
   */
  const fetchReviews = async (productIds: string[]) => {
    if (productIds.length === 0) return;

    const apiUrl = `${import.meta.env.VITE_EKOMI_BASE_URL}/bulk-average/${productIds.join(',')}`;

    loading.value = true;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
      });

      if (response.status !== 200) {
        throw new Error(`Failed to fetch reviews: ${response.statusText}`);
      }

      const data = await response.json();
      reviews.value = data.data;
    } catch (err) {
      error.value = err instanceof Error ? err.message : String(err);
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  return { reviews, loading, error, fetchReviews };
}
