<template>
  <section class="top-promotion-blocks pt-4 d-none d-md-block">
    <div class="text-center mb-4">
      <h2>
        {{ topPromotionsBlock.info.sectionTitle }}
      </h2>
      <p class="fs-7">
        {{ topPromotionsBlock.info.sectionSubTitle }}
      </p>
    </div>

    <div class="row">
      <div
        v-for="([key, promo]) in Object.entries(topPromotionsBlock.blocks)"
        :key="promo.title"
        class="col-md-4"
        :class="key"
      >
        <img :src="getPromoIcon(key)" />
        <p class="fs-5 fw-bold text-dark my-3">{{ promo.title }}</p>
        <div class="promo-main-text" v-html="promo.mainText"></div>

        <div
          v-if="promo.citiesList?.length > 0"
          class="d-flex flex-wrap mt-3 gap-2"
        >
          <div
            class="destinations"
            v-for="(city, index) in promo.citiesList"
            :key="`item-${index}`"
          >
            <a :href="city.url" class="btn border text-primary fw-medium transition hover-translate d-flex justify-content-center align-items-center py-2 px-3 rounded-pill text-decoration-none fs-6 text-center">
              {{ city.title }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
defineProps<{
  topPromotionsBlock: {
    info: any,
    blocks: {
      destinations: any,
      motivations: any,
      weeksTips: any,
    },
  },
}>();

const getPromoIcon = (key: string) => {
  switch (key) {
    case "destinations":
      return new URL("@/assets/images/promo_cities.svg", import.meta.url).href;
    case "motivations":
      return new URL("@/assets/images/promo_motivation.svg", import.meta.url).href;
    case "weeksTips":
      return new URL("@/assets/images/promo_tips.svg", import.meta.url).href;
  }

  return '';
};
</script>

<style scoped>
.destinations {
  a {
    border-color: #A7CDED !important;

    &:hover {
      background-color: #2c508e;
      color: #fff !important;
    }
  }
}

/* handle styling rendered by v-html */
::v-deep(.weeksTips .promo-main-text) {
  p {
    display: flex;
    flex-direction: column;
    gap: 12px;

    a {
      border: 1px solid #252525;
      border-radius: 10px;
      padding: 12px 24px;
      color: #252525;
      text-decoration: none;
      font-weight: 600;
      display: inline-block;
      width: fit-content;
    }
  }
}

::v-deep(.motivations .promo-main-text) {
  ul {
    padding-left: 4px;
    text-indent: -5px;

    li::marker {
      font-weight: normal;
      font-size: 0.8rem;
    }
  }
}
</style>