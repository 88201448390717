<template>
  <TopBar />

  <main class="p-2 p-md-4">
    <div class="container my-4">
      <div class="row">
        <div class="col-12 order-1">
          <h1 v-text="title" class="mb-4 fw-bold fs-2 lh-base text-dark d-block d-lg-none"></h1>
        </div>

        <div 
          class="gift-up-target col-12 col-lg-6 order-2" 
          :data-language="langMap[locale]" 
          :data-site-id="checkoutId" 
          data-platform="Other">
        </div>

        <div class="col-12 col-lg-6 order-3">
          <h1 v-text="title" class="mb-4 fw-bold fs-2 lh-base text-dark d-none d-lg-block"></h1>
          <p v-text="subtitle" class="mb-4 fw-bold fs-4 lh-base text-dark"></p>
          <p v-text="giftCardText" class="mb-4 fs-6 fw-normal text-dark"></p>
          <ul class="list-unstyled">
            <li v-for="bullet in bulletPoints" class="d-flex align-items-center mb-2">
              <img 
                src="@/assets/images/green-check.svg" 
                alt="Green check" 
                class="me-2 img-fluid"
              >
              <span v-text="bullet.text"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { useLocaleStore } from "@/stores/localeStore";
import TopBar from "@/components/TopBar.vue";

declare global {
  interface Window {
    giftup: (...args: any[]) => void;
  }
}

const props = defineProps<{
  locale: string,
  title: string,
  subtitle: string,
  giftCardText: string,
  bulletPoints: { text: string }[],
  checkoutId: string,
  giftCardUrl: string,
}>();

const localestore = useLocaleStore();
localestore.setLocale(props.locale);

(function (g: any, i: Document, f: string, t: string, u: string) {
  g[u] = g[u] || function () { (g[u].q = g[u].q || []).push(arguments) };
  const p = i.createElement(f);
  (p as HTMLScriptElement).async = true;
  (p as HTMLScriptElement).src = t;
  const s = i.getElementsByTagName(f)[0];
  if (s && s.parentNode) {
    s.parentNode.insertBefore(p, s);
  }
})(window, document, "script", "https://cdn.giftup.app/dist/gift-up.js", "giftup");

window.giftup("conversion", function (payload: { giftCards: any[]; }) {
  const gcUrl = `${props.giftCardUrl}/giftup/cards/${payload.giftCards.map(e => e.code).join(",")}/conversion`;

  fetch(gcUrl, {
    method: "POST",
    body: JSON.stringify({}),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    }
  }).catch(err => {
    console.log('gc conversion error', err);
  });
});

const langMap: { [key: string]: string } = {
  'en': 'en-GB',
  'nl': 'nl-NL',
  'de': 'de-DE',
  'da': 'da-DK'
};

</script>

<style>
</style>
