<template>
  <TopBar />

  <PageBanner
    :mainTitle="mainTitle"
    :topSlider="topSlider"
    class="d-none d-sm-block"
  />

  <main class="p-4 p-md-5 d-flex flex-column gap-4">
    <div class="container my-4">
      <div class="g-4 mb-4">
        <div class="top-text g-4 mb-3">
          <h1 class="fw-bold fs-2">{{ pageHeader }}</h1>
          <span class="subheader fw-bold mt-0 mb-2 fs-5">{{ pageSubheader }}</span>
        </div>

        <div
          v-if="firstContent"
          class="first-content text-tertiary g-4"
          v-html="firstContent"
        ></div>
      </div>

      <div class="tour-row row g-4">
        <div
          :class="`d-flex col-sm-${tour.colSizes.colSm} col-md-${tour.colSizes.colMd}`"
          v-for="(tour, index) in favouritePosts"
          :key="tour.title"
        >
          <div class="reviews-link d-none d-sm-block">
            <a :href="`${tour.permalink}#reviews-section`" class="ekomi-tour-reviews"></a>
          </div>

          <a
            :href="tour.permalink"
            id="link-to-product"
            class="w-100"
          >
            <div
              class="tour-box d-flex flex-column w-100 mb-4 rounded-3"
              :data-product-id="tour.productId"
            >
              <div class="box-image position-relative">
                <div class="overflow-hidden rounded-top">
                  <img
                    class="lazyload w-100"
                    :src="tour.tourImage.original"
                    :alt="tour.title"
                    :title="tour.title"
                  />
                  <div
                    v-if="tour.bannerOpt.banner_type"
                    class="d-none d-sm-block position-absolute rounded-3"
                    :class="`${tour.bannerOpt.banner_type}-banner`"
                    @mousemove="updateHintPosition($event, index)"
                    @mouseleave="hideHint"
                  >
                    <Icon type="fas" name="star" customClass="fa-inverse"/>
                    <span class="banner-text ms-1 text-white">{{ tour.bannerOpt.banner_text }}</span>
                  </div>
                  <div
                    class="banner-hint position-absolute rounded-2 text-white p-1"
                    v-show="visibleHint === index"
                    :style="{
                      top: hintTop + 'px',
                      left: hintLeft + 'px',
                    }"
                  >{{ tour.bannerOpt.banner_mouse_over }}</div>
                </div>

                <div class="box-info-section">
                  <div class="text-secondary">
                    <div class="text-tertiary mb-2">
                      {{ tour.duration === "n/a" ? "" : tour.duration }}
                    </div>
                    <div class="tour-title fw-bold text-secondary mb-2">{{ tour.postTitle }}</div>
                    <p class="fs-7 text-tertiary">{{ tour.description }}</p>
                  </div>

                  <div class="bottom-section text-secondary">
                    <ReviewStars
                      :withLink="false"
                      :average="reviews[tour.productId]?.average"
                      :total="reviews[tour.productId]?.total"
                    />
                    <div class="fs-6 fw-bold pt-2">{{ tour.price }}</div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div class="main-content g-4 mt-2" v-html="mainContent"></div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useLocaleStore } from "@/stores/localeStore";
import { useEkomiReviews } from "@/composables/useEkomiReviews";

import TopBar from "@/components/TopBar.vue";
import PageBanner from "@/components/PageBanner.vue";
import ReviewStars from "@/components/ReviewStars.vue";

const props = defineProps<{
  locale: string,
  mainTitle: string,
  mainContent: string,
  firstContent: string,
  pageHeader: string,
  pageSubheader: string,
  favouritePosts: any,
  topSlider: any,
}>();

const localestore = useLocaleStore();
localestore.setLocale(props.locale);

const hintLeft = ref(0);
const hintTop = ref(0);
const visibleHint = ref<number | null>(null);

const updateHintPosition = (event: MouseEvent, index: number) => {
  const overflowWrap = (event.currentTarget as HTMLElement).closest('.box-image');
  if (!overflowWrap) return;

  const overflowRect = overflowWrap.getBoundingClientRect();

  hintLeft.value    = event.clientX - overflowRect.left + 10;
  hintTop.value     = event.clientY - overflowRect.top + 10;
  visibleHint.value = index;
};

const hideHint = () => {
  visibleHint.value = null;
};

const { reviews, fetchReviews } = useEkomiReviews();
fetchReviews(props.favouritePosts
  .filter((post: any) => post.productId)
  .map((post: any) => post.productId)
);

</script>

<style scoped>
h1 {
  color: #252525;
  line-height: 40px;
}

.subheader {
  line-height: 28px;
}

.tour-box {
  float: left;
  border: 1px solid #e5e5e5;
  font-size: 15px;
  height: 95%;

  .box-image {
    min-height: 155px;

    img {
      object-fit: cover;
      height: 255px;
    }

    .corner-banner {
      left: 12px;
      top: 12px;
      background-color: #00AB3C;
      padding: 6px 12px;
      width: fit-content;

      .banner-text {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .banner-hint {
      max-height: 5em;
      min-width: 200px;
      max-width: 300px;
      border: 1px solid #fff;
      font-size: 13px;
      background: #00ab3c;
    }

    .box-info-section {
      padding: 20px 12px;

      .tour-title {
        font-size: 18px;
      }
    }
  }

  &:hover {
    box-shadow: 4px 4px 10px -3px rgba(0,0,0,.3);
  }
}

.container {
  &::v-deep(a) {
    color: #2C508E;
    text-decoration: none;
  }
}

/* TODO: Review this */
.main-content {
  &::v-deep(ul:first-of-type) {
    background-color: #F3F9FD;
    padding: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-radius: 16px;

    &:not(:has(li)) {
      display: none;
    }

    li {
      width: 30%;
      list-style: none;
    }

    @media (max-width: 768px) {
      li {
        width: 45%;
      }
    }

    @media (max-width: 576px) {
      li {
        width: 100%;
      }
    }
  }

  &::v-deep(h2) {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #252525;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  &::v-deep(h3), &::v-deep(h4) {
    margin-bottom: 20px;
    color: #252525;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    margin-top: 38px;
    margin: 1em 0;
  }

  &::v-deep(ul) {
    padding-left: 1em;

    li {
      color: #474747;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    a {
      color: #2c508e;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-decoration: none;
    }

    p {
      margin-bottom: 16px;
      color: #474747;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &::v-deep(p) {
    margin-top: 0;
    margin-bottom: 12px;
  }

  &::v-deep(ul:has(+ p)) {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
</style>