<template>
  <section class="main-content d-flex flex-column align-items-start gap-0 gap-lg-4">
    <div v-if="parsedContent.col1 && parsedContent.col2" class="d-flex flex-column flex-lg-row gap-lg-4">
      <div class="w-100 w-lg-50" v-html="parsedContent.col1"></div>
      <div class="w-100 w-lg-50" v-html="parsedContent.col2"></div>
    </div>
    <div v-if="parsedContent.fullWidth" v-html="parsedContent.fullWidth"></div>
    </section>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps<{ 
  mainContent: string
}>();

const parseMainContent = (content: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");

  const parts: string[] = [];
  for (let element of doc.body.children) {
    if (parts.length === 0 || element.tagName === 'H3') {
      parts.push("");
    }
    parts[parts.length - 1] += element.outerHTML;
  }

  switch (parts.length) {
    case 1: return { col1: "", col2: "", fullWidth: parts[0] };
    case 2: return { col1: parts[0], col2: parts[1], fullWidth: "" };
    case 3: return { col1: parts[0], col2: parts[1], fullWidth: parts[2] };
    default: return { col1: "", col2: "", fullWidth: parts.join('') };
  }
};

const parsedContent = ref(parseMainContent(props.mainContent));

</script>

<style scoped>
.main-content {
  margin-top: 52px;
  margin-bottom: 64px;

  &::v-deep(h2),
  &::v-deep(h3),
  &::v-deep(h4) {
    margin-bottom: 20px;
    color: #252525;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  &::v-deep(p) {
    margin-bottom: 20px;
    color: #474747;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  &::v-deep(ul) {
    list-style: disc;
    font-weight: 400;
    line-height: 24px;
  }

  &::v-deep(li) {
    color: #474747;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &::v-deep(a) {
    color: #2d508e;
    text-decoration: none;
  }
}
</style>
