<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_218_414)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.01798 0.617242C4.925 0.427119 5.86928 0.537905 6.70766 0.932804C7.5 1.30602 8.15693 1.91376 8.59056 2.6719L17.3308 2.6751C17.3906 2.67512 17.4484 2.69659 17.4938 2.73561L19.2094 4.21202C19.3093 4.29798 19.3253 4.44673 19.2459 4.55197L17.5303 6.82845C17.4892 6.88303 17.4275 6.91839 17.3596 6.92631C17.2918 6.93422 17.2236 6.91401 17.171 6.87036L15.8659 5.78713L14.6599 6.86443C14.5599 6.95373 14.4073 6.9483 14.314 6.85212L13.3055 5.81326L12.2615 6.85496C12.1643 6.95191 12.0071 6.95239 11.9094 6.85604L10.8701 5.83166L9.72686 7.28613C9.67954 7.34633 9.60723 7.38153 9.53065 7.38163L8.27062 7.38336C7.73353 8.08236 6.99204 8.59848 6.14765 8.85887C5.26207 9.13197 4.31159 9.10888 3.44032 8.79311C2.56904 8.47735 1.82447 7.88612 1.31948 7.10906C0.814497 6.332 0.576632 5.41148 0.641946 4.48705C0.707261 3.56263 1.07219 2.6847 1.68142 1.98636C2.29065 1.28803 3.11096 0.807364 4.01798 0.617242ZM4.89121 6.18976C5.66575 6.18976 6.29364 5.56187 6.29364 4.78732C6.29364 4.01278 5.66575 3.38489 4.89121 3.38489C4.11666 3.38489 3.48877 4.01278 3.48877 4.78732C3.48877 5.56187 4.11666 6.18976 4.89121 6.18976Z" fill="currentColor"/>
      <path d="M5.40186 23.6719C4.05713 23.6719 2.92993 23.217 2.02026 22.3074C1.1106 21.3977 0.655762 20.2705 0.655762 18.9258C0.655762 17.5811 1.11851 16.4539 2.04399 15.5442C2.96948 14.6345 4.08877 14.1797 5.40186 14.1797C6.62002 14.1797 7.64439 14.5436 8.47495 15.2713C9.30552 15.999 9.83154 16.9008 10.053 17.9766H10.67L8.96143 13.2305H7.30029V11.332H12.0464V13.2305H11.0022L11.3345 14.1797H15.8907L14.5144 10.3828H12.0464V8.48438H14.5144C14.9257 8.48438 15.2935 8.59512 15.6178 8.8166C15.9421 9.03809 16.1755 9.33867 16.3179 9.71836L17.9315 14.1322H18.6909C20.004 14.1322 21.1233 14.595 22.0488 15.5205C22.9743 16.4459 23.437 17.5652 23.437 18.8783C23.437 20.2072 22.9782 21.3384 22.0606 22.2718C21.1431 23.2052 20.0198 23.6719 18.6909 23.6719C17.5519 23.6719 16.5512 23.3159 15.689 22.604C14.8268 21.8921 14.2771 20.9824 14.0397 19.875H10.053C9.83154 20.9666 9.29365 21.8723 8.43935 22.5921C7.58506 23.312 6.57256 23.6719 5.40186 23.6719ZM5.40186 21.7734C6.05049 21.7734 6.60815 21.5955 7.07485 21.2395C7.54155 20.8835 7.86982 20.4287 8.05967 19.875H5.40186V17.9766H8.05967C7.86982 17.407 7.54155 16.9482 7.07485 16.6002C6.60815 16.2521 6.05049 16.0781 5.40186 16.0781C4.59502 16.0781 3.9187 16.351 3.3729 16.8968C2.8271 17.4426 2.5542 18.1189 2.5542 18.9258C2.5542 19.7168 2.8271 20.3892 3.3729 20.9429C3.9187 21.4966 4.59502 21.7734 5.40186 21.7734ZM12.7108 17.9766H14.0397C14.1188 17.6127 14.2256 17.2726 14.3601 16.9562C14.4946 16.6397 14.6726 16.3471 14.894 16.0781H11.9989L12.7108 17.9766ZM18.6909 21.7734C19.4978 21.7734 20.1741 21.4966 20.7199 20.9429C21.2657 20.3892 21.5386 19.7168 21.5386 18.9258C21.5386 18.1189 21.2657 17.4426 20.7199 16.8968C20.1741 16.351 19.4978 16.0781 18.6909 16.0781H18.596L19.5452 18.5936L17.7417 19.258L16.8399 16.7426C16.5235 17.0115 16.2783 17.3279 16.1043 17.6918C15.9303 18.0557 15.8433 18.467 15.8433 18.9258C15.8433 19.7168 16.1162 20.3892 16.662 20.9429C17.2078 21.4966 17.8841 21.7734 18.6909 21.7734Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_218_414">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script setup>
defineProps({
  color: {
    type: String,
    default: "#252525",
  },
});
</script>