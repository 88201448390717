import { ref } from "vue";

const CACHE_KEY = 'menu_cache';
const CACHE_EXPIRATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

export function useMenu() {
  const menu    = ref<Record<string, any>>({});
  const loading = ref(false);
  const error   = ref<string | null>(null);

  const fetchMenu = async (locale: string) => {
    // Check for cached menu
    const cacheLangKey   = `${CACHE_KEY}-${locale}`;
    const cachedMenu     = localStorage.getItem(cacheLangKey);
    const cacheTimestamp = localStorage.getItem(`${cacheLangKey}_timestamp`);

    if (cachedMenu && cacheTimestamp) {
      const age = Date.now() - parseInt(cacheTimestamp, 10);
      if (age < CACHE_EXPIRATION) {
        // Use cached menu
        return JSON.parse(cachedMenu);
      }
    }

    try {
      loading.value = true;

      const reqUrl = new URL(import.meta.env.VITE_MENU_URL, window.location.origin);
      // Fetch menu from server
      const response = await fetch(`${reqUrl}/${locale}/`);
      if (!response.ok) throw new Error('Failed to fetch menu');

      const menuData = await response.json();

      // Cache the menu and timestamp
      localStorage.setItem(cacheLangKey, JSON.stringify(menuData));
      localStorage.setItem(`${cacheLangKey}_timestamp`, Date.now().toString());

      menu.value = menuData;
      return menuData;
    } catch (error) {
      console.error('Error loading menu:', error);
    } finally {
      loading.value = false;
    }
  }

  return {menu, loading, error, fetchMenu};
}