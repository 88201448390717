import { ref, computed, onMounted, onUnmounted } from 'vue';

export function useMobile() {
  const width = ref(window.innerWidth);
  const isMobile = computed(() => width.value <= 767);
  const isWideScreen = computed(() => width.value > 991);
  const updateWidth = () => {
    width.value = window.innerWidth;
  };

  onMounted(() => {
    window.addEventListener('resize', updateWidth);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateWidth);
  });

  return { width, isMobile, isWideScreen };
}