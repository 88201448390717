<template>
  <TopBar />
  <main class="p-2 p-md-4 d-flex align-items-center">
    <div class="container my-4 d-flex justify-content-center">
      <div class="row text-center">
        <div class="col-xs-12">
          <img v-if="success_svg" :src="`${success_svg}`" alt="checkmark" width="100px" height="100px">
        </div>
        <div class="h3 col-xs-12 success-title"><b>{{ title }}</b></div>
        <div class="col-xs-12 success-subtext-1">{{ subtext_line_1 }}</div>
        <div class="col-xs-12 success-subtext-2">{{ subtext_line_2 }}</div>
        <div class="col-xs-12 mt-4">
          <button class="btn btn-success" :onclick="handleBtnClick">
            {{ $t('okay') }}
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import TopBar from '@/components/TopBar.vue';
import { useLocaleStore } from '@/stores/localeStore';

const props = defineProps<{
  locale: string,
  success_svg: string | boolean,
  title: string,
  subtext_line_1: string,
  subtext_line_2: string,
}>();

const { setLocale } = useLocaleStore();
setLocale(props.locale);

const handleBtnClick = () => {
  window.location.href = `/${props.locale}`;
}

</script>

<style scoped>
main {
  min-height: 75vh;
}
</style>