<template>
  <font-awesome-icon :icon="[type, name]" :size="size" :class="customClass" />
</template>

<script setup>
defineProps({
  name: { type: String, required: true },
  type: { type: String, default: "fas" }, // Default to Solid
  size: { type: String, default: "1x" },
  customClass: { type: String, default: "" }
});
</script>