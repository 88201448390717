import { ref } from 'vue';

interface City {
  post_id: string;
  url: string;
  title: string;
}

interface Country {
  post_id: string;
  url: string;
  title: string;
}

interface Tour {
  post_id: string;
  url: string;
  title: string;
  dates: string;
}

interface TourCategory {
  tour_type: string;
  tour_type_icon: string;
  tour_collection: Tour[];
  title: string;
  url: string;
}

interface Results {
  cities: City[];
  countries: Country[];
  tours: TourCategory[];
}

export function useSpotlight() {
  const loading = ref(false);
  const error = ref<string | null>(null);

  const results = ref<Results>();

  const fetchSpotlight = async (query: string, language: string) => {
    if (!query || !language) return;

    const apiUrl = import.meta.env.VITE_SPOTLIGHT_API_URL;
    loading.value = true;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query, language }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch spotlight results[${query}-${language}]: ${response.statusText}`);
      }

      const data = await response.json();

      const newResults = repositionTours(data.data);
      results.value = newResults;
    } catch (err) {
      error.value = err instanceof Error ? err.message : String(err);
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  return { loading, error, fetchSpotlight, results };
}

const repositionTours = (data: Results) => {
  let flattened: any = {};

  Object.entries(data).forEach(([type, group]) => {
    if (type === "tours") {
      for (const tourGroup of Object.values(group)) {
        const tourType = (tourGroup as any).tour_type;
        flattened[tourType] = (tourGroup as any).tour_collection || [];
      }
    } else {
      flattened[type] = group;
    }
  });

  return flattened;
};
