import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useLocaleStore = defineStore('locale', () => {
  const locale          = ref('nl');
  const footerMenu      = ref([]);
  const isFooterLoading = ref(true);
  const siteLanguages   = ref((<any>window).bb2025_ajax.siteLanguages);

  const setLocale = (lang: any) => {
    locale.value = lang;
  }

  const fetchFooterData = async () => {
    isFooterLoading.value = true;
    try {
      const response   = await fetch(`/wp-json/custom/v1/footer-data/?lang=${locale.value}`);
      const data       = await response.json();
      footerMenu.value = Array.isArray(data.footerMenu)
        ? data.footerMenu
        : Object.values(data.footerMenu);
    } catch (error) {
      console.error('Failed to fetch footer data:', error);
    } finally {
      isFooterLoading.value = false;
    }
  }

  return {
    locale,
    siteLanguages,
    footerMenu,
    isFooterLoading,
    setLocale,
    fetchFooterData,
  };
});