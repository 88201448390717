<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.84706 14.4054C9.74961 14.5113 9.66844 14.6356 9.60928 14.7759L6.87426 21.2607C6.59857 21.9143 6.90498 22.6677 7.55862 22.9434C8.21227 23.2191 8.96564 22.9127 9.24132 22.259L11.9206 15.9064C11.9172 15.9064 11.9138 15.9064 11.9103 15.9064C10.9458 15.9064 10.1283 15.2763 9.84706 14.4054Z" fill="currentColor"/>
    <path d="M10.1943 13.0822V7.79274C10.1943 6.59553 11.1649 5.625 12.3621 5.625C13.5061 5.625 14.4431 6.51118 14.5241 7.63453L16.6533 9.54728V4.97761C16.6533 4.59018 17.0749 4.34995 17.4082 4.54746L19.9606 6.06001C20.2829 6.25105 20.2884 6.7157 19.9705 6.91422L17.4915 8.46277V10.3002L17.6596 10.4513C17.9837 10.7425 18.0104 11.2412 17.7193 11.5654C17.4281 11.8895 16.9293 11.9162 16.6052 11.625L14.5298 9.76059V13.0822C14.5298 13.3875 14.4668 13.6779 14.3529 13.9414C14.3876 13.9975 14.4185 14.057 14.4449 14.1196L17.4594 21.2672C17.7351 21.9208 17.4287 22.6742 16.7751 22.9499C16.1214 23.2255 15.3681 22.9191 15.0924 22.2655L12.1283 15.2375C11.041 15.1209 10.1943 14.2005 10.1943 13.0822Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0184 6.85323C10.0184 5.90174 8.99761 5.29911 8.16451 5.75875L5.82711 7.04836C5.33281 7.32107 5.02588 7.84096 5.02588 8.4055V12.1418C5.02588 13.1083 5.80938 13.8918 6.77588 13.8918H8.26836C9.23486 13.8918 10.0184 13.1083 10.0184 12.1418V6.85323ZM8.51836 7.27668L6.55172 8.36172C6.53578 8.37052 6.52588 8.38729 6.52588 8.4055V12.1418C6.52588 12.2799 6.63781 12.3918 6.77588 12.3918H8.26836C8.40643 12.3918 8.51836 12.2799 8.51836 12.1418V7.27668Z" fill="#252525"/>
    <path d="M14.5297 2.99629C14.5297 4.12699 13.6131 5.04361 12.4824 5.04361C11.3517 5.04361 10.4351 4.12699 10.4351 2.99629C10.4351 1.86559 11.3517 0.948975 12.4824 0.948975C13.6131 0.948975 14.5297 1.86559 14.5297 2.99629Z" fill="currentColor"/>
  </svg>
</template>

<script setup>
defineProps({
  color: {
    type: String,
    default: "#252525",
  },
});
</script>