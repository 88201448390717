<template>
  <div class="position-relative w-100">
    <!-- Image Display -->
    <div class="position-relative overflow-hidden rounded">
      <picture>
        <!-- <source type="image/avif" :srcset="images[currentIndex].imgAvif">
        <source type="image/webp" :srcset="images[currentIndex].imgWebp"> -->
        <img
          alt="city"
          :src="images[currentIndex].imgUrl"
          width="100%"
          loading="lazy"
        >
      </picture>
    </div>

    <!-- Navigation Buttons -->
    <button
      class="nav-button left rounded-circle"
      @click="prevImage"
    >
      <span>&#x276E;</span> <!-- Unicode for left arrow -->
    </button>
    <button
      class="nav-button right rounded-circle"
      @click="nextImage"
    >
      <span>&#x276F;</span> <!-- Unicode for right arrow -->
    </button>

    <!-- Indicator Dots -->
    <div class="position-absolute bottom-0 start-50 translate-middle-x d-flex gap-2 mb-2">
      <span
        v-for="(image, index) in images"
        :key="index"
        class="indicator-dot"
        :class="{
          'active': currentIndex === index
        }"
        @click="goToImage(index)"
      ></span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps<{
  images: {
    imgUrl: string,
    imgAvif: string,
    imgWebp: string,
    imgJpg: string,
  }[];
}>();

const currentIndex = ref(0);

const prevImage = () => {
  currentIndex.value =
    (currentIndex.value - 1 + props.images.length) % props.images.length;
};

const nextImage = () => {
  currentIndex.value = (currentIndex.value + 1) % props.images.length;
};

const goToImage = (index: number) => {
  currentIndex.value = index;
};
</script>

<style scoped>
.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #2C508E;
  border: 3px solid white;
  border-radius: 12px;
  padding: 10px 18px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.left {
    left: -20px;
  }

  &.right {
    right: -20px;
  }
}

.nav-button:hover {
  background-color: #003d80;
}

.indicator-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
}

.indicator-dot.active {
  background-color: #0056b3;
}

img {
  object-fit: cover;
  object-position: center;
  height: 260px;
}

@media (min-width: 768px) {
  img {
    height: 420px;
  }
}
</style>