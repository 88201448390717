<template>
  <div class="top-bar position-relative">
    <MenuDesktop v-if="!isMobile" />
    <MenuMobile
      v-else
      :isHomePage="isHomePage"
    />
  </div>
</template>

<script lang="ts" setup>
import { useMobile } from "@/composables/useMobile";

import MenuDesktop from "@/components/MenuDesktop.vue";
import MenuMobile from "@/components/MenuMobile.vue";

const { isMobile } = useMobile();

defineProps<{
  isHomePage?: boolean,
}>();

</script>

<style scoped>
  section {
    padding: 8px 0;
  }
</style>
