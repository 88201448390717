<template>
  <div
    v-if="isOpen"
    class="modal position-fixed w-100 h-100 d-block"
  >
    <div class="modal-dialog modal-vertical-centered overflow-y-auto">
      <div class="modal-content px-3 py-4">
        <div class="p-2 text-center">
          <img src="@/assets/images/icon_modal_failed.svg" alt="Error Modal Icon" class="modal-icon">

          <Icon
            type="far"
            name="xmark"
            size="1x"
            customClass="xclose position-absolute p-1 cursor-pointer"
            @click="closeModal" />
        </div>

        <div class="modal-body px-4 py-3 text-center">
          <div class="modal-message mb-2">
            {{ modalMessage }}
          </div>
        </div>

        <div class="text-center">
          <button
            class="btn btn-default bg-blue px-3"
            @click="closeModal"
          >
            {{ $t("okay") }}
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useErrorStore } from "@/stores/errorStore";

const errorStore = useErrorStore();
const { showError: isOpen, message: modalMessage } = storeToRefs(errorStore);

const closeModal = () => {
  errorStore.clearError();
};
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, .5);

  .modal-content {
    border-radius: 16px;
  }

  .modal-vertical-centered {
    transform: translateY(150px);
  }

  .xclose {
    top: 10px;
    right: 10px;
  }

  button.bg-blue {
    background-color: #2C508E;
    color: #fff;

    &:hover {
      background-color: #234072;
    }
  }
}
</style>
