<template>
  <div class="px-3">
    <template v-for="(group, type) in results" :key="type">
      <div v-if="group && group.length" class="mb-4">
        <h6 class="fw-bold mb-2">
          {{ $t(`${type}`) }}
        </h6>
        <ul class="list-unstyled">
          <li
            v-for="(item, index) in (group)"
            :key="index"
            class="search-item d-flex align-items-center py-2 px-3 border-bottom cursor-pointer"
            @click="selectResult(item.url)"
          >
            <i :class="getIconClass(type)" class="me-3"></i>
            {{ item.title }}
            <span
              class="badge ms-auto booked-often d-flex gap-1"
              v-if="hasHighlights(item.title)"
            >
              <Icon type="fas" name="star"/>
              {{ $t('booked_often') }}
            </span>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>

defineProps<{
  results: Record<string, any> | undefined;
}>();

const hasHighlights = (title: string) => {
  return title.toLowerCase().includes('highlights');
}

const getIconClass = (type: string) => {
  if (type === "cities") return "fa fa-map-marker text-primary";
  if (type === "bike_tours") return "fa fa-bicycle text-warning";
  if (type === "walking_tours") return "fa fa-person-walking text-success";
  if (type === "bike_rental") return "fa fa-bicycle text-info";
  return "fa fa-bicycle text-warning";
};

const selectResult = (url: string) => {
  window.location.href = url;
};

</script>

<style scoped>
.search-item:hover {
  background-color: #f8f9fa;
}

.booked-often {
  background-color: #FFD141;
  color: #252525;

  i {
    color: #252525;
  }
}
</style>