<template>
  <div class="container languages-scroll">
    <div class="row row-cols-auto justify-content-between g-4">
      <div
        v-for="(language, key) in languagesWithTimeslots"
        :key="key"
        class="col text-center flex-grow-1"
        v-show="language.times.length > 0"
      >
        <div class="d-flex flex-column align-items-center w-100">
          <img width="20" height="14" :src="language.flag" class="flag-icon" />
          <h5>{{ language.name }}</h5>
          <button
            v-for="(time, tIndex) in language.times"
            :key="tIndex"
            class="btn btn-outline-primary m-2 w-100"
            :class="{ 'btn-primary text-white': clickedTime === time && clickedLanguage === key }"
            @click="setActiveTimeLang(time, key)"
          >
            {{ parseTimeslot(time).time }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, reactive, onMounted } from 'vue';
import { useLocaleStore } from '@/stores/localeStore';
import { parseTimeslot } from '@/helpers/parseTimeslot';

const props = defineProps<{
  chosenDate?: Record<string, string[]>;
  currentTime: string;
  currentLanguage: string;
}>();

const flags = {
  en: {
    display: 'English',
    icon: new URL("@/assets/images/flag-en.svg", import.meta.url).href,
  },
  nl: {
    display: 'Dutch',
    icon: new URL("@/assets/images/flag-nl.svg", import.meta.url).href,
  },
  de: {
    display: 'German',
    icon: new URL("@/assets/images/flag-de.svg", import.meta.url).href,
  },
  da: {
    display: 'Danish',
    icon: new URL("@/assets/images/flag-da.svg", import.meta.url).href,
  },
};

const emit = defineEmits(['selectedTime']);

const localeStore = useLocaleStore();

const clickedTime = ref(props.currentTime);
const clickedLanguage = ref(props.currentLanguage);

const setActiveTimeLang = (time: string, language: string) => {
  clickedLanguage.value = language;
  clickedTime.value = time;
  emit('selectedTime', time, language);
};

watch(() => props.chosenDate, (newChosenDate) => {
  if (newChosenDate) setTimeslots(newChosenDate);
});

const setTimeslots = (chosenDate: Record<string, string[]>) => {
  for (const language in chosenDate) {
    languagesWithTimeslots[language].times = chosenDate[language];
  }

  // select first timeslot available in locale
  if (languagesWithTimeslots[localeStore.locale].times[0]) {
    setActiveTimeLang(languagesWithTimeslots[localeStore.locale].times[0], localeStore.locale);
  }
}

const languagesWithTimeslots = reactive<Record<string, {
  name: string;
  flag: string;
  times: string[];
}>>({
  en: {
    name: flags["en"].display,
    flag: flags["en"].icon,
    times: []
  },
  nl: {
    name: flags["nl"].display,
    flag: flags["nl"].icon,
    times: []
  },
  de: {
    name: flags["de"].display,
    flag: flags["de"].icon,
    times: []
  },
  da: {
    name: flags["da"].display,
    flag: flags["da"].icon,
    times: []
  },
});

onMounted(() => {
  if (props.chosenDate) setTimeslots(props.chosenDate);
});

</script>

<style lang="scss" scoped>
.btn {
  border: 1px solid #A7CDED;
  color: #2C508E;
  font-weight: 600;
}
.btn-primary {
  background-color: #2C508E !important;
  color: white;
}
.btn-outline-primary {
  &:hover, &:active {
    background-color: #2C508E !important;
    color: white;
  }
}
.languages-scroll {
  width: 100%;
  max-width: 100%;
  padding: 0 10px;
}

</style>
