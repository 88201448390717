<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_218_417)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75826 9.28067C3.16513 9.8603 3.64185 10.5394 4.19958 11.348C4.40478 11.6444 4.84373 11.6444 5.04895 11.348C5.60668 10.5394 6.08341 9.86029 6.49028 9.28067C8.36471 6.61034 8.75659 6.05207 8.75659 4.6831C8.75659 2.40088 6.90649 0.550781 4.62427 0.550781C2.34204 0.550781 0.491943 2.40088 0.491943 4.6831C0.491943 6.05207 0.883819 6.61034 2.75826 9.28067ZM4.62481 5.96666C5.40284 5.96666 6.03356 5.33595 6.03356 4.55792C6.03356 3.77989 5.40284 3.14917 4.62481 3.14917C3.84678 3.14917 3.21606 3.77989 3.21606 4.55792C3.21606 5.33595 3.84678 5.96666 4.62481 5.96666Z" fill="currentColor"/>
      <path d="M5.4729 23.4492C4.12817 23.4492 3.00098 22.9944 2.09131 22.0847C1.18164 21.175 0.726807 20.0479 0.726807 18.7031C0.726807 17.3584 1.18955 16.2312 2.11504 15.3215C3.04053 14.4119 4.15981 13.957 5.4729 13.957C6.69106 13.957 7.71543 14.3209 8.546 15.0486C9.37656 15.7764 9.90259 16.6781 10.1241 17.7539H10.7411L9.03247 13.0078H7.37134V11.1094H12.1174V13.0078H11.0733L11.4055 13.957H15.9618L14.5854 10.1602H12.1174V8.26172H14.5854C14.9967 8.26172 15.3646 8.37246 15.6889 8.59395C16.0132 8.81543 16.2465 9.11602 16.3889 9.4957L18.0026 13.9096H18.762C20.075 13.9096 21.1943 14.3723 22.1198 15.2978C23.0453 16.2233 23.5081 17.3426 23.5081 18.6557C23.5081 19.9846 23.0493 21.1157 22.1317 22.0491C21.2141 22.9825 20.0909 23.4492 18.762 23.4492C17.6229 23.4492 16.6223 23.0933 15.7601 22.3813C14.8979 21.6694 14.3481 20.7598 14.1108 19.6523H10.1241C9.90259 20.7439 9.3647 21.6497 8.5104 22.3695C7.6561 23.0893 6.6436 23.4492 5.4729 23.4492ZM5.4729 21.5508C6.12153 21.5508 6.6792 21.3728 7.1459 21.0168C7.6126 20.6609 7.94087 20.2061 8.13071 19.6523H5.4729V17.7539H8.13071C7.94087 17.1844 7.6126 16.7256 7.1459 16.3775C6.6792 16.0295 6.12153 15.8555 5.4729 15.8555C4.66606 15.8555 3.98975 16.1284 3.44395 16.6742C2.89814 17.22 2.62524 17.8963 2.62524 18.7031C2.62524 19.4941 2.89814 20.1665 3.44395 20.7202C3.98975 21.2739 4.66606 21.5508 5.4729 21.5508ZM12.7819 17.7539H14.1108C14.1899 17.39 14.2967 17.0499 14.4312 16.7335C14.5656 16.4171 14.7436 16.1244 14.9651 15.8555H12.07L12.7819 17.7539ZM18.762 21.5508C19.5688 21.5508 20.2451 21.2739 20.7909 20.7202C21.3367 20.1665 21.6096 19.4941 21.6096 18.7031C21.6096 17.8963 21.3367 17.22 20.7909 16.6742C20.2451 16.1284 19.5688 15.8555 18.762 15.8555H18.667L19.6163 18.3709L17.8127 19.0354L16.911 16.5199C16.5946 16.7889 16.3494 17.1053 16.1753 17.4691C16.0013 17.833 15.9143 18.2443 15.9143 18.7031C15.9143 19.4941 16.1872 20.1665 16.733 20.7202C17.2788 21.2739 17.9551 21.5508 18.762 21.5508Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_218_417">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script setup>
defineProps({
  color: {
    type: String,
    default: "#252525",
  },
});
</script>