import { ref } from 'vue';
import { WaitingList } from '@/types/waiting-list';
import { useErrorStore } from "@/stores/errorStore";
import { i18n } from "@/plugins/i18n";

export function useWaitingList() {
  const loading = ref(false);
  const error = ref<string | null>(null);
  const errorStore = useErrorStore();

  const createWaitingList = async (waitingListData: WaitingList) => {
    const apiUrl = `${import.meta.env.VITE_WAITING_LIST_API_URL}`;
    error.value = null;
    loading.value = true;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(waitingListData),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(`Failed to create waiting list entry: ${data.error}`);
      }
  } catch (err) {
      const errMsg = err instanceof Error ? err.message : String(err);

      const translatedError = translateError(errMsg);
      errorStore.setError(translatedError);

      error.value = translatedError;
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  return { loading, error, createWaitingList };
}

// translate backend error to something user friendly
const translateError = (error: string) => {
  const t = i18n.global.t;

  if (error.includes("booking_date: must be no less than")) {
    return t("errorModal.check_date_try_again");
  }

  return t("errorModal.default_message");
}