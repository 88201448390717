import { mountLayout } from './services/layoutLoader';

import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/custom.css'; // Ensure this comes after Bootstrap
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';

config.autoAddCss = false;

mountLayout();
