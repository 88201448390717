import { ref } from 'vue';

export function usePhoneSubmission() {
  const isSubmitSuccessful = ref(false);
  const isSubmitFailed = ref(false);

  const submitPhoneNumber = async (token: string | null, customerPhone: string, isPhoneValid: boolean, errors: any) => {
    if (!isPhoneValid) {
      errors.phone = true;
      return;
    }

    try {
      const response = await fetch(`${window.location.origin}/wp-json/bajabikes/v1/add-phone-number/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          customer_phone: customerPhone,
        }),
      });

      if (response.ok) {
        isSubmitSuccessful.value = true;
      } else {
        isSubmitFailed.value = true;
      }
    } catch (error) {
      console.error("Error:", error);
      isSubmitFailed.value = true;
    }
  };

  return {
    isSubmitSuccessful,
    isSubmitFailed,
    submitPhoneNumber,
  };
}
