import { defineStore } from "pinia";
import { ref } from "vue";

export const useErrorStore = defineStore("errorStore", () => {
  const message = ref("");
  const showError = ref(false);

  function setError(errorMsg: string) {
    message.value = errorMsg;
    showError.value = true;
  }

  function clearError() {
    message.value = "";
    showError.value = false;
  }

  return { message, showError, setError, clearError };
});
