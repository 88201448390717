<template>
  <h2 class="text-center fw-bold d-none d-lg-block">{{ $t('recommended_by') }}</h2>
  <ItemCarousel
    v-if="mediaPartners.length > 0"
    class="my-5 d-none d-lg-flex text-center"
    :items="mediaPartners"
    item-key="postTitle"
    :auto-play-delay="5000"
    :items-per-view="itemsPerView"
    :auto-play="true"
    :draggable="true"
  >
    <template #item="{ item }">
      <img
        :src="item.postImage"
        :alt="item.postTitle"
        :title="item.postTitle"
        draggable="false"
        loading="lazy"
      >
    </template>
  </ItemCarousel>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import ItemCarousel from './ItemCarousel.vue';

defineProps<{
  mediaPartners: { postTitle: string; postImage: string; }[];
}>();

const itemsPerView = ref(4);

function updateItemsPerView() {
  itemsPerView.value = window.innerWidth < 1200 ? 3 : 4;
}

onMounted(() => {
  updateItemsPerView();
  window.addEventListener('resize', updateItemsPerView);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateItemsPerView);
});
</script>

<style scoped>
:deep(.nav-button) {
  border: none;
  width: 40px;
  height: 40px;
}
</style>