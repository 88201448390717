<template>
  <div class="menu">
    <div class="top-row d-flex justify-content-end px-5">
      <LanguageSwitcher />
      <!-- Login Button -->
      <button class="btn fs-7">
        <Icon type="far" name="fa-user" />
        Log In
      </button>
    </div>

    <div class="bottom-row position-relative d-flex justify-content-center px-4 py-1 gap-4" @mouseleave="hideAllPanels">
      <div class="d-flex align-items-center">
        <a :href="homeUrl">
          <img src="@/assets/images/bb-logo-white-blue.svg" alt="Baja Bikes" class="logo" />
        </a>
      </div>

      <!-- List of menu options -->
      <div class="menu-options d-flex fs-7">
        <nav class="py-3 px-4">
          <ul class="d-flex gap-3 list-unstyled mb-0">
            <li
              v-for="(menuItem, index) in menuData"
              :key="index"
              class="fw-bold cursor-pointer"
              @click="selectMenuOption(menuItem.title)"
            >
              <span
                :class="{
                  'pb-2 text-primary':
                    selectedMenuOption?.title === menuItem.title,
                }"
              >
                {{ menuItem.title }}
              </span>
            </li>
          </ul>
        </nav>

        <!-- List of continents, countries, cities -->
        <div
          v-if="selectedMenuOption"
          class="secondary-menu position-absolute bg-white shadow"
        >
          <!-- Continents -->
          <div class="d-flex border-bottom">
            <ul class="d-flex justify-content-center list-unstyled mb-0 w-100">
              <li
                v-for="(continent, index) in getContinents()"
                :key="index"
                class="cursor-pointer px-3 py-2 fw-bold"
                :class="{
                  'd-none': index === 0,
                  'text-primary border-bottom border-4 border-primary': selectedContinent?.title === continent.title
                }"
                @click="selectContinent(continent.title)"
              >
                {{ continent.title }}
              </li>
            </ul>
          </div>

          <div v-if="selectedContinent" class="d-flex gap-4">
            <!-- Countries -->
            <div class="countries flex-fill bg-light p-4">
              <h3 class="fs-6 fw-bold mb-3">
                {{ getCountries()?.[0]?.title }}
              </h3>
              <ul class="list-unstyled">
                <li
                  v-for="(country, index) in getCountries()"
                  :key="index"
                  class="cursor-pointer py-1 px-3 text-start fw-medium"
                  :class="{
                    'd-none': index === 0,
                    'bg-primary text-white rounded-pill': selectedCountry?.title === country.title,
                    'selected-country': selectedCountry?.title === country.title,
                  }"
                  @click="selectCountry(country.title)"
                >
                  {{ country.title }}
                </li>
              </ul>
            </div>

            <!-- Cities -->
            <div v-if="selectedCountry" class="cities flex-fill bg-white p-4">
              <h3 class="fs-6 fw-bold mb-3">
                {{ getCities()?.[0]?.title }}
              </h3>
              <ul class="list-unstyled">
                <li
                  v-for="(city, index) in getCities()"
                  :key="index"
                  class="cursor-pointer"
                  :class="{ 'd-none': index === 0 }"
                >
                  <a class="btn fs-7" :href="city.url">
                    {{ city.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from "vue";
import { useMenu } from "@/composables/useMenu";
import { useLocaleStore } from "@/stores/localeStore";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import type { ContinentWithCountries, ContinentWithId, CountryWithCities, CountryWithId, MenuRootItem } from "@/types/menu";

// handle menu data
const { fetchMenu } = useMenu();
const menuData = ref<MenuRootItem[]>([]);
const localeStore = useLocaleStore();
const locale = localeStore.locale;

const homeUrl = computed(() => `${window.location.origin}/${locale}/`);

onBeforeMount(async () => {
  const menuJson = await fetchMenu(locale);
  menuData.value = menuJson.map((item: any) => {
    return buildMenuTree(item);
  });
});

const buildMenuTree = (data: any) => {
  function findChildren(parentId: string) {
    return data
      .filter((item: any) => `${item.parentId}` === `${parentId}`)
      .map((item: any) => {
        const children = findChildren(item.id);

        // If children are found, decide whether they're cities or countries
        if (children.length > 0) {
          if (children.every((child: any) => !child.children && !child.children)) {
            // countries - All children are cities
            return {
              title: item.title,
              children: children,
            };
          } else {
            // continents - Children are countries
            return {
              title: item.title,
              children: children,
            };
          }
        } else {
          // no more nodes, this is the city
          return item;
        }
      });
  }

  // Find the root node
  const rootNodes = data.filter((item: any) => item.parentId === "0");
  if (rootNodes.length !== 1) {
    console.error("Data must have exactly one root node.");
    return {}; // our menu data is not clean, sometimes there's multiple root nodes in one category
  }

  // Build tree starting from the root node
  const rootNode = rootNodes[0];
  const tree = {
    title: rootNode.title,
    children: findChildren(rootNode.id),
  };

  return tree;
}

// State for selected continent, country, and cities
const selectedMenuOption = ref<MenuRootItem | null>(null);
const selectedContinent = ref<ContinentWithCountries | ContinentWithId | null>(null);
const selectedCountry = ref<CountryWithCities | CountryWithId | null>(null);

const selectMenuOption = (menuOption: string | null) => {
  selectedMenuOption.value = menuData.value?.find((item: MenuRootItem) => item.title === menuOption) || null;
  selectedContinent.value = null;
}

const selectContinent = (continentName: string) => {
  selectedContinent.value = selectedMenuOption.value?.children.find((c: any) => c.title === continentName) || null;
  selectedCountry.value = null;
};

const selectCountry = (countryName: string) => {
  return selectedCountry.value = selectedContinent.value?.children?.find((c: any) => c.title === countryName) || null;
};

const getContinents = () => {
  return selectedMenuOption.value?.children || [];
};

const getCountries = () => {
  return selectedContinent.value?.children || [];
};

const getCities = () => {
  return selectedCountry.value?.children || [];
};

const hideTimer = ref<ReturnType<typeof setTimeout> | null>(null);
const hideAllPanels = () => {
  hideTimer.value = setTimeout(() => {
    selectedMenuOption.value = null;
    selectedContinent.value = null;
    selectedCountry.value = null;
  }, 400);
};

// PAD-2592 find a pattern in root nodes to determine which icons
const categoriesIcons = ref([
  { name: ['Destinations'], icon: '@/assets/images/category_destinations.svg' },
  { name: ['Bike Tours'], icon: '@/assets/images/category_bike_tour.svg' },
  { name: ['Bike Rental'], icon: '@/assets/images/category_bike_rental.svg' },
  { name: ['Mountain Bike Tours'], icon: '@/assets/images/category_mountain_bike_tours.svg' },
  { name: ['Walking Tours'], icon: '@/assets/images/category_walking_tours.svg' },
]);
// End menu section
</script>

<style scoped>
.menu {
  .top-row {
    background-color: #2C508E;

    .btn, :deep(.btn) {
      color: #fff;
    }

    :deep(.dropdown-menu li:hover) {
      color: #2C508E;
    }
  }

  .secondary-menu {
    top: 100%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, 0%);
    width: 80%;
  }

  .bottom-row {
    background-color: #FFD141;
  }

  .selected-country {
    display: inline-block;
    min-width: 80px;
  }
}
</style>
