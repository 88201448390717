<template>
  <div
    class="carousel-items-container align-items-center"
    @mouseenter="stopAutoPlay"
    @mouseleave="startAutoPlay"
  >
    <button
      class="nav-button prev bg-white text-tertiary"
      @click="slide('prev')"
    >
      <Icon :type="iconType" name="chevron-left" :size="iconSize"/>
    </button>

    <div class="carousel-items py-3 w-100 overflow-hidden">
      <div
        class="items-grid d-flex"
        :class="{ 'cursor-grab': draggable, 'cursor-grabbing': isDragging }"
        :style="gridOffset"
        ref="sliderRef"
        @mousedown="startDrag"
        @mousemove="onDrag"
        @mouseup="endDrag"
        @mouseleave="endDrag"
        @touchstart="startDrag"
        @touchmove="onDrag"
        @touchend="endDrag"
      >
        <div
          v-for="item in displayedItems"
          :key="itemKey ? item[itemKey] : item"
          class="item-slot"
        >
          <slot name="item" :item="item"></slot>
        </div>
      </div>
    </div>

    <button
      class="nav-button next bg-white text-tertiary"
      @click="slide('next')"
    >
      <Icon :type="iconType" name="chevron-right" :size="iconSize"/>
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue';

const props = withDefaults(defineProps<{
  items: any[];
  itemKey?: string;
  autoPlayDelay?: number;
  itemsPerView?: number;
  autoPlay?: boolean;
  draggable?: boolean;
  iconType?: string;
  iconSize?: string;
}>(), {
  itemsPerView: 4,
  autoPlay: false,
  draggable: false,
  iconType: 'fas',
  iconSize: 'xl'
});

const sliderRef        = ref<HTMLElement | null>(null);
const itemWidth        = computed(() => (100 / props.itemsPerView));
const position         = ref(0);
const displayedItems   = ref([...props.items]);
const isDragging       = ref(false);
const dragStart        = ref({ x: 0, position: 0 });
const currentTranslate = ref(0);

// Auto-play configuration
const AUTO_PLAY_DELAY     = props.autoPlayDelay || 3000;
const TRANSITION_DURATION = 300;
let autoPlayTimer: number | null = null;

// This is used to offset the grid to the left when there are more items than the number of items per view,
// so that there are no empty slots on the left side of the carousel. This allows to drag the carousel to the left (homepage media partners)
const gridOffset = computed(() => {
  const offset = displayedItems.value.length - props.itemsPerView;
  if (offset <= 1) return {};
  return {
    left: `${-itemWidth.value * (Math.min(offset - 1, 3))}%`
  };
});

function animateSlider(targetPosition: number, transition = true) {
  if (!sliderRef.value) return;
  sliderRef.value.style.transition = transition ? `transform ${TRANSITION_DURATION}ms ease` : 'none';
  sliderRef.value.style.transform  = `translateX(${targetPosition}%)`;
}

function slide(direction: 'next' | 'prev') {
  if (direction === 'prev') {
    // For prev: first move the last item to the beginning
    displayedItems.value.unshift(displayedItems.value.pop()!);
    position.value = -itemWidth.value; // Start from moved position
    animateSlider(-itemWidth.value, false); // Instantly move to start position

    // Then animate to final position
    requestAnimationFrame(() => {
      position.value = 0;
      animateSlider(0, true);
    });
  } else {
    // For next: keep existing behavior
    const targetPosition = -itemWidth.value;
    position.value = targetPosition;
    animateSlider(targetPosition, true);

    setTimeout(() => {
      displayedItems.value.push(displayedItems.value.shift()!);
      position.value = 0;
      animateSlider(0, false);
    }, TRANSITION_DURATION);
  }
}

function getPositionX(event: MouseEvent | TouchEvent): number {
  return event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
}

function startDrag(event: MouseEvent | TouchEvent) {
  if (!props.draggable) return;
  stopAutoPlay();
  isDragging.value = true;
  dragStart.value = {
    x: event instanceof MouseEvent ? event.clientX : event.touches[0].clientX,
    position: position.value
  };
  currentTranslate.value = position.value;
  if (sliderRef.value) {
    sliderRef.value.style.transition = 'none';
  }
}

function onDrag(event: MouseEvent | TouchEvent) {
  if (!isDragging.value) return;
  event.preventDefault();
  const currentX = getPositionX(event);
  const diff     = currentX - dragStart.value.x;
  const drag     = (diff / sliderRef.value?.offsetWidth!) * 100;
  currentTranslate.value = dragStart.value.position + drag;
  animateSlider(currentTranslate.value, false);
}

function endDrag() {
  if (!isDragging.value) return;
  const dragDistance = currentTranslate.value - dragStart.value.position;

  // Calculate how many items we've dragged past
  const itemsToMove = Math.round(dragDistance / itemWidth.value);

  if (Math.abs(itemsToMove) > 0) {
    // Calculate the final position based on number of items to move
    const targetPosition = dragStart.value.position + (itemsToMove * itemWidth.value);
    position.value = targetPosition;

    // Move multiple items with a single animation
    animateSlider(targetPosition, true);

    // Update the array after animation
    setTimeout(() => {
      for (let i = 0; i < Math.abs(itemsToMove); i++) {
        if (itemsToMove > 0) {
          const lastItem = displayedItems.value.pop();
          if (lastItem) displayedItems.value.unshift(lastItem);
        } else {
          const [firstItem] = displayedItems.value.splice(0, 1);
          displayedItems.value.push(firstItem);
        }
      }
      position.value = 0;
      animateSlider(0, false);
    }, 300); // Match this with your transition duration
  } else {
    // Snap back to original position if drag wasn't far enough
    animateSlider(dragStart.value.position);
    position.value = dragStart.value.position;
  }

  isDragging.value = false;
  startAutoPlay();
}

function startAutoPlay() {
  if (!props.autoPlay) return;
  stopAutoPlay();
  autoPlayTimer = window.setInterval(() => {
    if (!isDragging.value) {
      slide('next');
    }
  }, AUTO_PLAY_DELAY);
}

function stopAutoPlay() {
  if (!props.autoPlay) return;
  if (autoPlayTimer) {
    clearInterval(autoPlayTimer);
    autoPlayTimer = null;
  }
}

onMounted(() => {
  startAutoPlay();
});

onUnmounted(() => {
  stopAutoPlay();
});
</script>

<style scoped>
.carousel-items-container {
  position: relative;
  .carousel-items {
    .items-grid {
      will-change: transform;
      user-select: none;
      touch-action: pan-y;
      position: relative;
    }
  }

  .carousel-items {
    .cursor-grab {
      cursor: grab;
    }

    .cursor-grabbing {
      cursor: grabbing;
    }
  }

  .item-slot {
    flex: 0 0 v-bind(itemWidth + '%');

    img {
      object-fit: contain;
      pointer-events: none;
      user-select: none;
      max-width: 100%;
      height: auto;
    }
  }

}
</style>