<template>
  <div class="card-container">
    <a
      class="card border-0 overflow-hidden m-3 text-white text-decoration-none"
      :href="permalink"
    >
      <picture>
        <source v-if="localImage !== image.origImg" type="image/avif" :srcset="image.imgAvif">
        <source v-if="localImage !== image.origImg" type="image/webp" :srcset="image.imgWebp">
        <img
          :alt="city"
          :src="localImage"
          @error="handleError"
          width="100%"
          loading="lazy"
        >
      </picture>
      <div class="card-img-overlay d-flex flex-column justify-content-end align-items-start">
        <h5 class="card-title mb-0 text-left fw-semibold fs-2">{{ city }}</h5>
        <p
          class="card-text m-0"
          v-if="tours !== '0' || reviews !== '0'"
        >
          {{ tours }} tours, {{ reviews }} reviews
        </p>
      </div>
    </a>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps<{
  city: string,
  tours: string,
  reviews: string,
  permalink: string,
  image: {
    imgAvif: string,
    imgWebp: string,
    imgJpg: string,
    origImg: string,
  },
}>();

const localImage = ref(props.image.imgJpg);

const handleError = () => {
  localImage.value = props.image.origImg;
}
</script>

<style scoped>
.card {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  &:hover img {
    transform: scale(1.2);
  }

  img {
    transition: transform 0.8s ease-in-out;
    height: 240px;
    object-fit: cover;
  }

  .card-img-overlay {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.6) 100%);

    h5 {
      line-height: 40px;
    }

    .card-title,
    .card-text {
      position: relative;
      z-index: 2;
    }
  }
}
</style>