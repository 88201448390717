<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0009 10.8341C22.0004 8.92057 21.4509 7.04734 20.4175 5.43679C19.4435 3.91865 18.0439 2.66797 16.4269 1.86971C13.117 0.25094 9.05535 0.614259 6.07689 2.78356C4.15275 4.18497 2.78482 6.28543 2.25171 8.60418C1.21138 13.129 3.44504 17.9137 7.83067 19.9264C8.32665 20.154 8.93201 19.925 9.15716 19.4345C9.3852 18.9376 9.15548 18.334 8.66519 18.109L8.63748 18.0962C7.80235 17.7092 7.0437 17.1826 6.3925 16.5425L7.29122 15.9251C7.96051 15.4773 8.47836 14.8368 8.77622 14.0886L9.73928 11.6797L11.3226 11.2837C11.7816 11.1691 12.1987 10.9266 12.5253 10.5844C12.852 10.2422 13.0749 9.81409 13.1681 9.35022L13.4821 7.78019C13.5984 7.1982 13.5039 6.59375 13.2155 6.07513C12.9271 5.5566 12.4632 5.15735 11.9077 4.94908L8.48471 3.65177C10.7953 2.52113 13.5764 2.58256 15.8358 3.81662C16.2494 4.04256 16.6406 4.30369 17.0055 4.59623L15.8855 6.83014C15.7386 7.12383 15.6509 7.44382 15.6277 7.77135C15.6044 8.09888 15.6459 8.42778 15.7498 8.73928L16.306 10.3999C16.4687 10.9222 17.0531 11.2266 17.5782 11.0508C18.0973 10.8771 18.3888 10.2861 18.2021 9.76509L17.6462 8.10738C17.6046 7.98178 17.6143 7.84437 17.6735 7.72604L18.4719 6.13367C18.6172 6.33363 18.7536 6.54073 18.8805 6.75442C19.6133 7.98915 20.0003 9.39846 20.0009 10.8343C20.001 11.3701 20.4651 11.8342 21.0009 11.8342C21.5368 11.8342 22.0009 11.37 22.0009 10.8341ZM11.5208 7.38798C11.5443 7.2712 11.5253 7.14988 11.4672 7.04587C11.4089 6.94136 11.3142 6.86187 11.2024 6.81998L6.49265 5.03599C5.82398 5.67086 5.2673 6.41921 4.85042 7.25013C4.20653 8.53353 3.91992 9.96657 4.02066 11.3989C4.10965 12.6641 4.49796 13.8875 5.15057 14.9695L6.17231 14.2681C6.50884 14.0439 6.76917 13.7225 6.91865 13.3468L8.07267 10.4627C8.13124 10.3157 8.22396 10.1844 8.34306 10.0802C8.46221 9.97586 8.60442 9.90134 8.75801 9.86273L10.8385 9.34461C11.0128 9.29032 11.1717 9.21301 11.2078 8.95794L11.5208 7.38798Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5799 11.6343C14.1637 11.6343 12.2049 13.593 12.2049 16.0093C12.2049 16.7284 12.3105 17.2492 12.6622 17.9314C13.0017 18.5899 13.5753 19.4071 14.4963 20.7191L14.5126 20.7424C14.9188 21.321 15.3944 21.9985 15.9504 22.8047C16.2547 23.2442 16.9054 23.2439 17.2096 22.8043C17.7657 21.9981 18.2411 21.3209 18.6473 20.7423L18.6636 20.7191C19.5845 19.4071 20.1582 18.5899 20.4977 17.9314C20.8494 17.2492 20.9549 16.7284 20.9549 16.0093C20.9549 13.593 18.9962 11.6343 16.5799 11.6343ZM16.5799 14.7281C15.9414 14.7281 15.4237 15.2457 15.4237 15.8843C15.4237 16.5229 15.9414 17.0406 16.5799 17.0406C17.2185 17.0406 17.7362 16.5229 17.7362 15.8843C17.7362 15.2457 17.2185 14.7281 16.5799 14.7281Z" fill="currentColor"/>
  </svg>
</template>

<script setup>
defineProps({
  color: {
    type: String,
    default: "#252525",
  },
});
</script>