<template>
  <div class="container mt-4">
    <form 
      v-if="!isSubmitSuccessful && !isSubmitFailed" 
      novalidate 
      class="mt-3" 
      @submit.prevent="submitForm"
    >
      <div class="row mb-3">
        <label for="name" class="col-md-4 col-form-label">
          {{ $t('name') }} *
        </label>
        <div class="col-md-6">
          <input 
            id="name" 
            type="text" 
            name="name" 
            class="form-control" 
            v-model.trim="form.name" 
            required 
          />
        </div>
      </div>

      <div class="row mb-3">
        <label for="email" class="col-md-4 col-form-label">
          {{ $t('email') }} *
        </label>
        <div class="col-md-6">
          <input
            id="email"
            type="email"
            name="email"
            class="form-control"
            v-model="form.email"
            required
          />
        </div>
      </div>

      <div class="row mb-3">
        <label for="city" class="col-md-4 col-form-label">
          {{ $t('which_city') }} *
        </label>
        <div class="col-md-6">
          <select 
            id="city" 
            class="form-select" 
            v-model="form.city" 
            required 
          >
            <option value="" disabled> 
              {{ $t('select_city') }} 
            </option>
            <option 
              v-for="city in cities" 
              :key="city" 
              :value="city">{{ city }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mb-3">
        <label for="message" class="col-md-4 col-form-label">
          {{ $t('message') }}
        </label>
        <div class="col-md-6">
          <textarea 
            id="message" 
            name="message" 
            class="form-control" 
            v-model="form.message"
          >
          </textarea>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6 offset-md-4">
          <div class="g-recaptcha" :data-sitekey="dataSiteKey"></div>
        </div>
      </div>

      <div v-if="errors.length > 0" class="mt-3 mb-3 text-danger">
        <div 
          v-for="error in errors" 
          :key="error" 
          class="text-center"
        >
        {{ $t(`${error}`) }}
        </div>
      </div>

      <div class="text-center">
        <button class="btn btn-primary rounded-3" type="submit">
          {{ $t('send') }}
        </button>
      </div>
    </form>

    <div v-else>
      <span class="fs-5 fw-bold"> 
        {{ isSubmitSuccessful ? $t('success_heading') : $t('error_heading') }} 
      </span>
      <p> 
        {{ isSubmitSuccessful ? $t('success_message') : $t('error_message') }} 
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useLocaleStore } from '@/stores/localeStore';

defineProps<{
  cities: string[]
}>();

const errors = ref<string[]>([]);
const isSubmitSuccessful = ref(false);
const isSubmitFailed = ref(false);
const dataSiteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

const localeStore = useLocaleStore();
const locale = localeStore.locale;

const form = ref({
  name: '',
  email: '',
  city: '',
  message: '',
  gRecaptchaResponse: '',
  pageUrl: window.location.href
});

onMounted(() => {
  const recaptchaScript = document.createElement('script');
  recaptchaScript.src = `https://www.google.com/recaptcha/api.js?hl=${locale}`;
  recaptchaScript.async = true;
  recaptchaScript.defer = true;
  document.head.appendChild(recaptchaScript);
});

function validateEmail(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

async function submitForm() {
  errors.value = [];

  const recaptchaResponse = (document.querySelector("#g-recaptcha-response") as HTMLTextAreaElement)?.value;
  form.value.gRecaptchaResponse = recaptchaResponse;

  if (!form.value.name || !form.value.email || !form.value.city) {
    errors.value.push('fill_required_message');
  } else if (!validateEmail(form.value.email)) {
    errors.value.push('email_error_message');
  } else if (!recaptchaResponse) {
    errors.value.push('captcha_error_message');
  }

  if (errors.value.length > 0) {
    return;
  }

  try {
    const formData = new FormData();
    const queryStr = new URLSearchParams({
      name: form.value.name,
      email: form.value.email,
      city: form.value.city,
      message: form.value.message,
      'g-recaptcha-response': form.value.gRecaptchaResponse
    }).toString();

    formData.append('action', 'sendContactFormEmail');
    formData.append('query', queryStr);
    formData.append('gRecaptchaResponse', form.value.gRecaptchaResponse);
    formData.append('pageUrl', form.value.pageUrl);

    const response = await fetch(`${window.location.origin}/wp/wp-admin/admin-ajax.php`, {
      method: 'POST',
      body: formData,
    });

    const responseData = await response.json();

    if (responseData.errors) {
      isSubmitFailed.value = true;
    } else {
      isSubmitSuccessful.value = true;
    }
  } catch (error) {
    console.error("Error:", error);
  }
}
</script>

<style scoped>
.btn {
  padding: 10px 24px;
  background: #337ab7;
  border: none;

  &:hover, &:focus {
    background: #286090;
  }
}
</style>