<template>
  <section class="top-destinations">
    <div class="text-center">
      <h2>{{ $t('top_destinations') }}</h2>
      <span class="fs-7">{{ $t('top_destinations_description') }}</span>
    </div>

    <div
      :class="{ 'd-lg-none': items.length > itemsPerView }"
      class="d-flex overflow-auto scroll-area"
    >
      <div
        class="card-item border-0"
        v-for="destination in items"
        :key="destination.postTitle"
      >
        <DestinationCard
          :city="destination.postTitle"
          tours="0"
          reviews="0"
          :permalink="destination.permalink"
          :image="destination.image"
        />
      </div>
    </div>

    <ItemCarousel
      v-if="items.length > itemsPerView"
      class="d-none d-lg-flex"
      :items="items"
      :item-key="itemKey"
      :items-per-view="itemsPerView"
      :icon-type="'far'"
    >
      <template #item="{ item }">
        <!-- TODO: Add tours and reviews -->
        <DestinationCard
          :city="item.postTitle"
          tours="5"
          reviews="123"
          :permalink="item.permalink"
          :image="item.image"
        />
      </template>
    </ItemCarousel>

    <div class="d-flex justify-content-center align-items-center">
      <a
        class="btn btn-success text-center"
        :href="viewAll.link"
      >
        {{ viewAll.text }}
      </a>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue';
import type { CityData } from '@/types/city';
import ItemCarousel from './ItemCarousel.vue';
import DestinationCard from './DestinationCard.vue';

defineProps<{
  viewAll: any,
  items: CityData[],
}>();

const itemsPerView = ref(4);
const itemKey = ref('postTitle');

function updateItemsPerView() {
  itemsPerView.value = window.innerWidth < 1200 ? 3 : 4;
}

onMounted(() => {
  updateItemsPerView();
  window.addEventListener('resize', updateItemsPerView);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateItemsPerView);
});
</script>

<style scoped>
h2 {
  font-weight: 700;
  font-size: 32px;
}

p {
  font-size: 18px;
  font-weight: 400;
}

.card-item {
  min-width: 256px;
}

.scroll-area {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.scroll-area::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

:deep(.nav-button) {
  position: absolute;
  border: 1px solid #2c508e;
  border-radius: 50%;
  z-index: 10;
  color: #2c508e !important;
  width: 40px;
  height: 40px;

  &.prev {
    left: -4px;
  }

  &.next {
    right: -4px;
  }
}
</style>